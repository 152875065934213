<template>
  <div class="relative pt-6 pb-6 bg-gray-800">
    <Popover>
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <nav
          class="relative flex items-center justify-between sm:h-10 md:justify-center"
          aria-label="Global"
        >
          <div
            class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0"
          >
            <div class="flex items-center justify-between w-full md:w-auto">
              <a :href="`/${$store.state.lang}`">
                <span class="sr-only">Emusavirim</span>
                <img
                  class="h-12"
                  src="../../public/img/e-musavirim.png"
                  alt="Workflow"
                />
              </a>
              <div class="-mr-2 flex items-center md:hidden">
                <PopoverButton
                  class="bg-gray-600 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span class="sr-only">Open main menu</span>
                  <MenuIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
          </div>
          <div class="hidden md:flex md:space-x-10">
            <a
              v-for="item in navigation"
              :key="item.name"
              :href="item.href"
              class="font-medium text-white hover:text-gray-300"
              >{{ item.name }}</a
            >
          </div>
          <div
            class="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0"
          >
            <span class="inline-flex rounded-md shadow">
              <a
                :href="`/${$store.state.lang}/app/login`"
                class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
              >
                {{ $t("message.myAccount") }}
              </a>
            </span>
          </div>
        </nav>
      </div>
      <transition
        enter-active-class="duration-150 ease-out"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="duration-100 ease-in"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <PopoverPanel
          focus
          class="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div
            class="rounded-lg shadow-md bg-gray-700 ring-1 ring-black ring-opacity-5 overflow-hidden"
          >
            <div class="px-5 pt-4 flex items-center justify-between">
              <div></div>
              <div class="-mr-2">
                <PopoverButton
                  class="bg-gray-600 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="px-2 pt-2 pb-3">
              <a
                v-for="item in navigation"
                :key="item.name"
                :href="item.href"
                class="block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-gray-500 hover:bg-gray-50"
                >{{ item.name }}</a
              >
            </div>
            <a
              :href="`/${this.$store.state.lang}/app/login`"
              class="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-100 hover:bg-gray-200"
            >
              {{ $t("message.login") }}
            </a>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
  <div class="max-w-7xl mx-auto p-4 sm:px-6 lg:px-8">
    <div class="max-w-7xl flex items-center justify-center space-y-5 p-5">
      <div class="flex-col">
        <div
          class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
        >
          Gizlilik Politikası
        </div>
        <div class="flex-col">
          <div class="text-lg font-medium text-gray-900 mb-3 mt-7">
            1. Genel
          </div>
          <div class="text-gray-700">
            Bundan böyle “Emüşavirim.net” olarak anılacak www.emusavirim.net web
            sitesini ziyaret edenlerin gizliliğini korumayı ilke olarak
            benimsemiştir. Bu nedenle, işbu gizlilik beyanı(“Gizlilik Beyanı”),
            web sitemizi kullanırken kullanıcılara ilişkin ne tür
            bilgilerinEmüşavirim.net tarafından alındığını, bunların nasıl
            kullanıldığını ve korunduğunu açıklamaktadır. Ayrıca işbu Gizlilik
            Beyanı çerçevesinde bu bilgilerin doğruluğunun sizin tarafınızdan
            nasıl denetlenebileceği ve istediğinizde Emüşavirim.net’e başvurarak
            bu bilgilerin silinmesini nasıl sağlayabileceğiniz belirtilmektedir.
            Söz konusu bilgilerin, mevcut ve geçerli olan veri koruma
            düzenlemelerine uygun olarak işlenmesi ve kullanılması için gerekli
            olan her türlü işlem Emüşavirim.net tarafından yerine
            getirilecektir. İşbu Gizlilik Beyanı,Emüşavirim.net sitesinden link
            verilmiş başka siteler(hyperlinked web siteleri) için geçerli
            değildir.
          </div>
        </div>
        <div class="flex-col">
          <div class="text-lg font-medium text-gray-900 mb-3 mt-7">
            2. Kişisel bilgilerin toplanması, kullanımı ve işlenmesi
          </div>
          <div class="text-gray-700">
            Belirli bir kişiye ait kullanılacak ve işlenecek bilgiler, yalnızca
            bu kişinin kendi istemiyle veri girmesi ya da bu hususta açıkça
            muvafakat vermesi halinde mümkündür. Veri girilmesi veya bu hususta
            açıkça muvafakat verilmesi, kişinin aşağıda belirtilen şartlar ile
            mutabık olduğunun göstergesidir. Sitemizi ziyaret ettiğinizde,
            sistem yönetimi, istatistik ve “back up” uygulamalarının
            gerektirdiği bazı bilgiler sunucularımızda otomatik olarak
            depolanacaktır. Bu verilerin arasında Internet servis sağlayıcınızın
            ismi, bazı durumlarda IP adresiniz, web gezgini yazılımınızın
            versiyonu, erişim sağlanan bilgisayarın işletim sistemi, bize
            yönlendiren websitesi, sitemizdeyken ziyaret ettiğiniz diğer siteler
            ve sitemizi bulmak için kullandığınız arama sözcükleri
            bulunmaktadır. Duruma bağlı olarak bu tür veriler, site ziyaretçisi
            hakkında bazı sonuçlar çıkarılmasını sağlar. Ancak hiçbir kişisel
            bilgi bu çerçevede kullanılmayacaktır. Bu tür kişiye özel veriler
            sadece anonimleştirilerek kullanılabilir. Emüşavirim.net’in bu
            verileri dışarıdan bir hizmet sağlayıcısına yönlendirmesi durumunda,
            verilerin, veri güvenliğine ilişkin mevcut yasal düzenlemeler
            çerçevesinde aktarılması için gerekli teknik ve organizasyonel
            işlemler gerçekleştirilecektir. Kendi isteğinizle kişisel
            bilgilerinizin verilmesi halinde, Emüşavirim.net, bu bilgileri
            yasada öngörülen veya ziyaretçinin muvafakat beyannamesinde
            belirtilen sınırlamalar dahilinde kullanacağını, işleme tabi
            tutulacağını veya aktarılacağını taahhüt eder. Söz konusu kişisel
            bilgilerinin üçüncü kişilere aktarımı, sadece adli ya da idari
            mercilerce öngörülmesi halinde söz konusu olacaktır. İşbu Gizlilik
            Beyanı´na ilişkin olası değişiklikler bu sayfada yayımlanacaktır. Bu
            çerçevede, size ait hangi bilgilerin saklandığına ve bu bilgilerin
            nasıl kullanıldığına dair güncel olarak bilgi almanıza olanak
            sağlanacaktır.
          </div>
        </div>
        <div class="flex-col">
          <div class="text-lg font-medium text-gray-900 mb-3 mt-7">
            3. Güvenlik
          </div>
          <div class="text-gray-700">
            Emüşavirim.net, size ait bilgileri güvenli biçimde saklamaktadır;
            dolayısıyla söz konusu bilgilerin herhangi bir kayıba, suistimale ya
            da değişikliğe karşı korunması için gerekli tüm önlemleri
            almaktadır. Emüşavirim.net’in, gerekli hizmeti size ulaştırmak için
            bilgilerinize erişimi olan sözleşmesel ortakları, sözleşmesel
            yükümlülükleri çerçevesinde bu bilgileri gizli tutmak ve başka
            hiçbir amaçla kullanmamakla yükümlüdür. Bazı durumlarda kişisel
            bilgilerinizin Emüşavirim.net’e bağlı şirketleriyle paylaşılması
            gerekecektir. Bu durumlarda da bilgileriniz gizli adledilecektir.
          </div>
        </div>
        <div class="flex-col">
          <div class="text-lg font-medium text-gray-900 mb-3 mt-7">
            4. Çocuklara ait, kişiye dair bilgiler
          </div>
          <div class="text-gray-700">
            Emüşavirim.net web sitesinin yetişkinler tarafından kullanılması
            öngörülmüş olup Emüşavirim.net web sitesi çoçuklara yönelik
            değildir. Emüşavirim.net, 14 yaşından küçük çoçuklara ilişkin
            bilgilerle ilgilenmemektedir. Emüşavirim.net, söz konusu çoçuklara,
            herhangi bir kişiye ait bilgileri göndermemeleri konusunda sitede
            uygun bir yerde açık yönlendirmelerde bulunacaktır. Ebeveynlerin ya
            da yasal velinin, gözetimleri altındaki çocukların herhangi bir
            kişiye ilişkin bilgileri Emüşavirim.net’e ulaştırıldığına dair
            şüpheleri olması halinde, eğer söz konusu bilgilerin silinmesi
            isteniyorsa, aşağıdaki adresten(6. Maddeye bkz.) derhal bizle
            irtibata geçmelerini rica ederiz. Böyle bir durumda, söz konusu
            bilgiler hiç vakit kaybetmeden silinecektir.
          </div>
        </div>
        <div
          class="flex-col"
          @click="
            navigation[0].current = false;
            navigation[1].current = true;
            navigation[2].current = false;
          "
        >
          5.Çerez("Cookie"ler)
        </div>
        <div class="flex-col">
          <div class="text-lg font-medium text-gray-900 mb-3 mt-7">
            6. Web İzleme
          </div>
          <div class="text-gray-700">
            Bu web sitesiçeşitli teknolojiler kullanarak pazarlama ve
            optimizasyon amaçlı veri toplar ve saklar. Bu veriler
            anonimleştirilmiş kullanıcı profilleri oluşturmak için
            kullanılabilir. Çerezler ayarlanmış olabilir. Kullanıcılarımız
            tarafından açık bir anlaşma olmaksızınbu teknolojiler ile toplanan
            veriler kişisel bir ziyaretçinin kimliğini belirlemek için
            kullanılmaz ve anonim herhangi diğer kişisel bilgileri ile
            birleştirilmez.
          </div>
        </div>
        <div class="flex-col">
          <div class="text-lg font-medium text-gray-900 mb-3 mt-7">
            7. İstekleriniz ve sorularınız
          </div>
          <div class="text-gray-700">
            Depolanan bilgiler, yasal veya sözleşmesel dosyalama süresi sona
            erdiğinde veya Emüşavirim.net’e artık gereksinimi kalmaması halinde
            Emüşavirim.net tarafından silinecektir. Ancak, elbette siz
            istediğiniz zaman size ait bilgilerin silinmesini talep
            edebilirsiniz. Ayrıca gelecekte, istediğiniz zaman kişisel
            bilgilerinizin kullanım ya da işleme tabi tutulmasına dair
            muvakatinizi geri çekebilirsiniz. Bu tür durumlarda ya da kişisel
            bilgilerinize ait diğer tüm sorularınız konusunda elektronik posta
            yoluyla (info@Emüşavirim.net) danışabilirsiniz. Size ait bilgilerin
            saklanıp ve bu bilgilerin kullanım alanlarına ilişkin sorularınız
            için bizimle yine aynı yöntemle temasa geçebilirsiniz. Dileklerinize
            anında yanıt vermek için elimizden gelen tüm çabayı
            göstereceğimizden emin olabilirsiniz.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/website/Footer";
import { PopoverButton, Popover, PopoverPanel } from "@headlessui/vue";
import { MenuIcon } from "@heroicons/vue/outline";
export default {
  components: {
    Footer,
    PopoverButton,
    MenuIcon,
    Popover,
    PopoverPanel,
  },
};
</script>
<style scoped lang="scss">
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .app-container {
    max-width: 441px;
  }
}

.pr-20 {
  padding-right: 20px;
}

.bg-gray {
  background: #ededed;
}

.display-none {
  display: none;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
