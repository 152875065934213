<template>
  <div class="relative pt-6 pb-6 bg-gray-800">
    <Popover>
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <nav
          class="relative flex items-center justify-between sm:h-10 md:justify-center"
          aria-label="Global"
        >
          <div
            class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0"
          >
            <div class="flex items-center justify-between w-full md:w-auto">
              <a :href="`/${this.$store.state.lang}`">
                <span class="sr-only">Emusavirim</span>
                <img
                  class="h-12"
                  src="../../public/img/e-musavirim.png"
                  alt="Workflow"
                />
              </a>
              <div class="-mr-2 flex items-center md:hidden">
                <PopoverButton
                  class="bg-gray-600 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span class="sr-only">Open main menu</span>
                  <MenuIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
          </div>
          <div class="hidden md:flex md:space-x-10">
            <a
              v-for="item in navigation"
              :key="item.name"
              :href="item.href"
              class="font-medium text-white hover:text-gray-300"
              >{{ item.name }}</a
            >
          </div>
          <div
            class="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0"
          >
            <span class="inline-flex rounded-md shadow">
              <a
                :href="`/${this.$store.state.lang}/app/login`"
                class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
              >
                {{ $t("message.myAccount") }}
              </a>
            </span>
          </div>
        </nav>
      </div>
      <transition
        enter-active-class="duration-150 ease-out"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="duration-100 ease-in"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <PopoverPanel
          focus
          class="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div
            class="rounded-lg shadow-md bg-gray-700 ring-1 ring-black ring-opacity-5 overflow-hidden"
          >
            <div class="px-5 pt-4 flex items-center justify-between">
              <div></div>
              <div class="-mr-2">
                <PopoverButton
                  class="bg-gray-600 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="px-2 pt-2 pb-3">
              <a
                v-for="item in navigation"
                :key="item.name"
                :href="item.href"
                class="block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-gray-500 hover:bg-gray-50"
                >{{ item.name }}</a
              >
            </div>
            <a
              :href="`/${this.$store.state.lang}/app/login`"
              class="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-100 hover:bg-gray-200"
            >
              {{ $t("message.login") }}
            </a>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>

  <div class="max-w-7xl mx-auto p-4 sm:px-6 lg:px-8">
    <div class="max-w-7xl flex items-center justify-center space-y-5 p-5">
      <div class="flex-col">
        <div class="flex-col">
          <div class="text-gray-900">(1) TARAFLAR</div>
          <div class="text-gray-700 p-3">
            Bu Sözleşme SONSUZ AKADEMİ EĞİTİM HİZMETLERİ LTD ŞTİ,ile Müşteri
            arasında aşağıdaki şart ve koşullarda mutabık kalınarak
            akdedilmiştir. İşbu Sözleşme’de Emüşavirim.net ve Müşteri ayrı ayrı
            "Taraf", birlikte "Taraflar" olarak adlandırılacaktır.<br />
            <br />
            EMÜŞAVİRİM İletişim Bilgileri:<br />
            Adı: SONSUZ AKADEMİ EĞİTİM HİZMETLERİ LTD ŞTİ<br />
            Adres: Fulya Mahallesi Büyükdere Cad. No:74/D Ofis:10 Şişli
            İstanbul<br />
            İnfo: info@emusavirim.net<br />
            Telefon: +90 (212) 949 0 363
          </div>
        </div>
        <div class="flex-col">
          <div class="text-gray-900">(2) TANIMLAR</div>
          <div class="text-gray-700 p-3">
            <span class="font-semibold">Başkanlık:</span>T.C. Maliye Bakanlığı
            Gelir İdaresi Başkanlığı’nı, <br />

            <span class="font-semibold">Muhasebe Hizmetleri:</span>3568 sayılı
            Serbest Muhasebecilik, Serbest Muhasebeci Mali Müşavirlik ve Yeminli
            Mali Müşavirlik Kanunu kapsamında iş sahiplerine/vergi yükümlülerine
            sunulan ve Muhasebe Hizmetleri Sözleşmesi’nde detaylandırılan ve
            sınırlandırılan hizmetleri,<br />

            <span class="font-semibold">Muhasebe Hizmetleri Sözleşmesi:</span
            >Platform’u kullanan ve Emüşavirim.net hizmetlerinden yararlanan
            3568 sayılı Serbest Muhasebecilik, Serbest Muhasebeci Mali
            Müşavirlik ve Yeminli Mali Müşavirlik Kanunu uyarınca Muhasebe
            Hizmetleri sunmaya yetkili kişiler (Platform Kullanıcısı) ile
            Müşteri arasında akdedilen ve Muhasebe Hizmetleri’ne ilişkin hüküm
            ve koşulların düzenlendiği sözleşmeyi,<br />

            <span class="font-semibold">Paket: </span>Bu Sözleşme’de
            detaylandırılan Emüşavirim.net hizmetlerinin biri veya birkaçını
            içeren, Emüşavirim.net tarafından içeriği ve ücretleri tek taraflı
            olarak değiştirilebilen, https://emusavirim.net/#packets alan adlı
            adreste yer alan ve Platform’a kayıt esnasında Müşteri tarafından
            seçilen veya sonrasında Müşteri tarafından değiştirilebilen
            paketleri,<br />

            <span class="font-semibold">
              Yetkili Kullanıcı, Yetkili Kullanıcı Kodu ve Bilgileri:</span
            >
            Kendisine, Emüşavirim.net tarafından sunulacak hizmetleri kullanması
            için giriş yetkisi verilmiş Müşteri’yi ve/veya Muhasebe Hizmetleri
            Sözleşmesi kapsamında Müşteri’ye Muhasebe Hizmetleri sunacak üçüncü
            kişi iş ortaklarını ve sunulan hizmete erişim için verilecek olan
            kullanıcı kodları ve bilgilerini ifade eder. Bu Sözleşme kapsamında
            Ürün ve Hizmetler’in sunulabilmesi için Muhasebe Hizmetleri
            Sözleşmesi’nin tarafı Muhasebeci Platform üzerinde doğrudan
            Müşteri’nin Yetkili Kullanıcılarından biri olarak atanacaktır.
            Müşteri’nin Site için kullanmakta olduğu kullanıcı kodları ve
            bilgileri Uygulama için de geçerli olacaktır. Müşteri, Site ve
            Uygulama’ya aynı kullanıcı kodu ve bilgileriyle giriş yapabilecek ve
            bu Sözleşme kapsamında Ürün ve Hizmetler’den yararlanabilecektir.<br />
          </div>
        </div>
        <div class="flex-col">
          <div class="text-gray-900">(3) SÖZLEŞME’NİN KONUSU</div>
          <div class="text-gray-700 p-3">
            Müşteri https://emusavirim.net alan adlı internet sitesine (Site)
            ve/veya hizmetlerin sunulacağı sanal mağazalar aracılığıyla
            Müşteri’nin mobil cihazlarına yükleyebildiği Emüşavirim.net adlı
            mobil uygulamaya (Uygulama) (ayrı ayrı biri veya ikisi birlikte
            Platform) üye olarak Emüşavirim.net’in bu Sözleşme kapsamında
            sunduğu hizmetlerin kendisine sunulmasını istemektedir.
            Emüşavirim.net bu Sözleşme kapsamında Müşteri’ye bu Sözleşme’de
            belirtilen hizmetleri sunmak istemektedir. Bu Sözleşme’nin amacı,
            Sözleşme’de detaylandırılan hizmetlerin Emüşavirim.net tarafından
            Müşteri’ye sağlanması ve/veya sağlanmasına aracılık edilmesi
            karşılığında Müşteri’nin ilgili ücretleri ödemesine ilişkin koşullar
            ve Taraflar’ın hak ve yükümlülüklerini düzenlemektedir.
          </div>
        </div>
        <div class="flex-col">
          <div class="text-gray-900">(4) TARAFLAR’IN HAK VE YÜKÜMLÜLÜKLERİ</div>
          <div class="text-gray-700 p-3">
            • <span class="font-semibold">4.1. </span> Bu Sözleşme, Kullanım
            Koşulları ve/veya Platform’da ilgili bölümlerde çeşitli vasıtalarla
            açıklandığı üzere; Emüşavirim.net işbu sözleşme’de detaylandırılan
            hizmetlerin (tamamı, bir ve/veya birkaçı birlikte Ürün ve Hizmetler)
            doğrudan kendisi tarafından ve/veya bu Sözleşme’de ve/veya
            Platform’da belirtildiği şekilde üçüncü kişi iş ortaklarıyla
            sağlanması amacıyla buna aracılık edilmesi hizmetlerini Müşteri’ye
            sunmaktadır. <br /><br />
            • <span class="font-semibold">4.2. </span>Emüşavirim.net tarafından
            Müşteri’ye sunulacak veya sunulmasına aracılık edilecek Ürün ve
            Hizmetler, aşağıdaki gibi detaylandırılmıştır. Emüşavirim.net Ürün
            ve Hizmetler’in ve/veya Paket’in kapsamı ve ücretlendirmeye ilişkin
            detayları ve bunlardaki değişiklikleri, Müşteri’nin e-mail adresine
            gerçekleştireceği bildirimle veya Platform’da uygun gördüğü şekilde
            ilan edebilir. Bu detaylar veya değişikliklerin uygulanabilmesi için
            Müşteri’nin kabulü gerekmez. Platform’da yer alan Ürün ve
            Hizmetler’e ilişkin diğer düzenlemeler ve açıklamalar da bu Sözleşme
            dahilinde olup; Taraflar’ın uyması gereken hak ve
            yükümlülüklerdendir.<br />
            <br />
            <div class="mt-5 px-5">
              ◦
              <span class="font-semibold">4.2.1.E-Dönüşüm Hizmetleri:</span>
              Emüşavirim.net özel entegratör sıfatını haiz iş ortağı şirket
              tarafından Müşteri’ye elektronik fatura (e-fatura), elektronik
              arşiv fatura (e-arşiv fatura) ve elektronik serbest meslek makbuzu
              (e-SMM) hizmetlerinin sunulmasını sağlar. Emüşavirim.net,
              E-Dönüşüm Hizmetleri’ni bizzat sunmamakta, iş ortakları tarafından
              Müşteri’ye sunulmasına yalnızca aracılık etmektedir.<br />
              <div class="mt-5 px-5">
                ◦ <span class="font-semibold">4.2.1.1.</span>
                Bu Sözleşme’de belirtilen; e-fatura, e-arşiv veya e-SMM
                hizmetlerine ilişkin özel hükümler, Müşteri’nin ilgili hizmet
                kapsamında ticari faaliyetinin söz konusu olması durumunda
                geçerli olacaktır. Bu bakımdan e-fatura, e-arşiv veya e-SMM
                hizmetlerinden yalnızca bir veya birkaçının kullanımı söz konusu
                olabilir. Müşteri, kullanmadığı hizmetlere ilişkin, sadece bu
                Sözleşme’de yer alması sebebiyle Emüşavirim.net’den herhangi bir
                hak veya talepte bulunamaz.
                <br />
                ◦<span class="font-semibold"> 4.2.1.2.</span>Emüşavirim.net,
                E-Dönüşüm Hizmetleri’nin Müşteri’ye sunulması için Gelir İdaresi
                Başkanlığı nezdinde özel entegratör sıfatını haiz iş
                ortaklarıyla birlikte faaliyet gösterir. Bu kapsamda söz konusu
                E-Dönüşüm Hizmetleri özel entegratör sıfatını haiz iş ortakları
                tarafından sunulacak olup; Emüşavirim.net bu iş ortakları adına
                Müşteri’yle sözleşmeler akdedilmesine aracılık etmeye
                yetkilidir. Her şartta ilgili mevzuatın izin verdiği kapsamda
                e-Dönüşüm Hizmetleri’nin ifa edilebilmesi için iş ortakları, alt
                yükleniciler ve/veya ilgili üçüncü kişilerle birlikte
                çalışılabilir veya bazı hizmetleri bunlara gördürülebilir.
              </div>
              <div class="mt-5">
                ◦<span class="font-semibold"> 4.2.2.</span>Muhasebe
                Hizmetleri:Muhasebe Hizmetleri, 3568 sayılı Serbest
                Muhasebecilik, Serbest Muhasebeci Mali Müşavirlik ve Yeminli
                Mali Müşavirlik Kanunu uyarınca bu hizmetleri sunmaya yetkili
                üçüncü kişilerce sunulmaktadır. Emüşavirim.net, Muhasebe Hizmeti
                sunmaz. İşbu Muhasebe Hizmetleri, Müşteri ile ilgili üçüncü kişi
                arasında akdedilen Muhasebe Hizmetleri Sözleşmesi’nde
                detaylandırılmıştır.
              </div>
              <br />
              <div class="mt-5 px-5">
                ◦ <span class="font-semibold">4.2.2.1.</span> Emüşavirim.net
                Muhasebe Hizmetleri Sözleşmesi’nin herhangi bir şekilde tarafı
                olmayıp; işbu sözleşme Müşteri ile Platform Kullanıcısı arasında
                akdedilir. Bu sebeple, Muhasebe Hizmetleri Sözleşmesi’ndeki hak
                ve yükümlülüklerin herhangi bir şekilde ihlal edilmesi, Muhasebe
                Hizmetleri’nin eksik ve/veya ayıplı ifa edilmesi gibi doğrudan
                Muhasebe Hizmetleri Sözleşmesi’ne ilişkin hususlarda
                Emüşavirim.net muhatap kabul edilemez ve Emüşavirim.net’den
                herhangi bir şey hak ve talep edilemez. Ancak Emüşavirim.net,
                kendi takdirinde olmak üzere Müşteri ile Platform Kullanıcısı
                arasındaki ticari ilişkiye Müşteri memnuniyeti kapsamında kendi
                takdiri sınırlarını çizecek şekilde dahil olabilir.
              </div>
              <br />
              <div class="mt-5">
                ◦ <span class="font-semibold">4.2.3.</span>Adres Temini
                Hizmetleri:Emüşavirim.net, Müşteri’nin ticari faaliyetini
                sürdürebilmesi amacıyla Müşteri’ye adres temini için, doğrudan
                kendisi veya ilgili adresi kiraya vermeye yetkili üçüncü kişi iş
                ortaklarının yetkilendirdiği sınırlar dahilinde bu Sözleşme’den
                ayrı bir sözleşme akdedebilir. (Sanal Ofis Hizmetleri
                Sözleşmesi) Sanal Ofis Hizmetleri Sözleşmesi kapsamındaki
                hizmetlerin Emüşavirim.net tarafından sunulması halinde, Sanal
                Ofis Hizmetleri Sözleşmesi bu Sözleşme’nin eki ve ayrılmaz bir
                parçası niteliğindedir.
                <div class="px-5 mt-5">
                  ◦<span class="font-semibold"> 4.2.3.1.</span>Müşteri’nin
                  ticari faaliyetlerini sürdürebilmesi amacıyla yasal bir adresi
                  bulunuyorsa ve Müşteri talep etmiyorsa Sanal Ofis Hizmetleri
                  Sözleşmesi akdedilmesi zorunluluk değildir. Müşteri bu
                  Sözleşme’yi akdetmek istiyorsa buna ilişkin Emüşavirim.net ile
                  iletişime geçmelidir, aksi halde Emüşavirim.net’in bu Sanal
                  Ofis Hizmetleri Sözleşmesi’ni akdetmek veya Müşteri’ye Adres
                  Temini de dahil olmak üzere, Sanal Ofis Hizmetleri Sözleşmesi
                  kapsamındaki hizmetleri sunmak gibi bir yükümlülüğü
                  bulunmamaktadır.
                </div>
              </div>
              <div class="mt-5">
                ◦<span class="font-semibold"> 4.2.3.2.4.2.3.1’</span>inci madde
                saklı kalmak kaydıyla, Emüşavirim.net Müşteri’ye Ürün ve
                Hizmetler’i sunabilmek için Müşteri’nin yasal ticari adresi
                olmasının zorunlu olduğunu öngördüğü durumlarda, Müşteri’den
                Sanal Ofis Hizmetleri Sözleşmesi akdetmesini veya yasal ticari
                adresinin bulunduğunu ve açık adresi kanıtlayan bilgi ve
                belgeleri kendisine Platform üzerinden veya fiziki evrak olarak
                ulaştırmasını talep edebilir. Aksi halde Emüşavirim.net ‘in
                Müşteri’ye hizmet sunmama hakkı saklıdır.<br />
              </div>
              <div class="mt-5">
                ◦<span class="font-semibold"> 4.2.4.</span>Elektronik İmza Temin
                Hizmetleri:Emüşavirim.net, Elektronik İmza Kanunu ve ilgili
                mevzuat uyarınca, güvenli elektronik imza, elektronik sertifika
                ve zaman damgasına ilişkin hizmetleri sağlamaya yetkili
                elektronik sertifika Emüşavirim.net iş ortakları tarafından
                Müşteri’ye Elektronik İmza Temin Hizmetleri’nin(e-İmza
                Hizmetleri)sunulmasına aracılık eder. Emüşavirim.net, e-İmza
                Hizmetleri’ni bizzat sunmamakta, iş ortakları tarafından
                Müşteri’ye sunulmasına yalnızca aracılık etmektedir. Bu e-İmza
                Hizmetleri Müşteri ile ilgili elektronik sertifika
                Emüşavirim.net iş ortağı arasında akdedilen sözleşmeler ve
                taahhütnamelerde detaylandırılmıştır.<br />
                <div class="px-5 mt-5">
                  ◦ <span class="font-semibold">4.2.4.1.</span> Emüşavirim.net,
                  e-İmza Hizmetleri’ne ilişkin sözleşmelerin herhangi bir
                  şekilde tarafı olmayıp, sadece e-İmza Hizmetleri’nin
                  Müşteri’ye sunulabilmesi için aracı ve kolaylaştırıcı işleve
                  sahiptir. Bu sebeple, e-İmza Hizmetleri’ne ilişkin
                  sözleşmelerdeki hak ve yükümlülüklerin herhangi bir şekilde
                  ihlal edilmesi, yahut eksik ve/veya ayıplı ifa edilmesi gibi
                  doğrudan e-İmza Hizmetleri sözleşmelerine ve ilgili mevzuata
                  ilişkin hususlarda Emüşavirim.net muhatap kabul edilemez ve
                  Emüşavirim.net’den herhangi bir şey hak ve talep edilemez.
                  Ancak Emüşavirim.net, kendi takdirinde olmak üzere Müşteri ile
                  iş ortağı arasındaki ticari ilişkiye Müşteri memnuniyeti
                  kapsamında kendi takdiri sınırlarını çizecek şekilde dahil
                  olabilir.<br />
                </div>
              </div>
              <div class="mt-5">
                ◦<span class="font-semibold"> 4.2.5.</span>Çevrimiçi Finans ve
                İş Yönetimi Uygulamaları Hizmetleri:Müşteri’nin online olarak
                finansal hususlar ve iş yönetimi gerçekleştirebilmesi için,
                Emüşavirim.net’in ilgili üçüncü kişi iş ortaklarıyla
                gerçekleştirdiği iş birliği kapsamında Platform üzerinden
                kullanılabilecek şekilde ön muhasebe programı hizmeti
                sunulmaktadır. Çevrimiçi Finans ve İş Yönetimi Uygulamaları
                Hizmetleri, Emüşavirim.net tarafından sunulmamakta, yalnızca bu
                Sözleşme’de ve Platform’da belirtildiği şekilde Platform
                üzerinden kullanılmasına imkan tanınmaktadır. Bu hizmeti
                kullanmak isteyen Müşteriler, bu Sözleşme’ye ek olarak ilgili
                hizmete ilişkin özel şart ve koşulların yer aldığı kullanım
                metinlerini de onaylamalıdır. İşbu kullanım metinleri de bu
                Sözleşme’nin ayrılmaz bir parçasıdır.<br />
              </div>
              <div class="mt-5">
                ◦ <span class="font-semibold">4.2.6. </span> KOSGEB Girişimcilik
                Desteği Bilgilendirme Hizmeti:Müşteri’nin talebi ve KOSGEB
                Girişimcilik Desteği için gerekli şartları taşıması halinde,
                Emüşavirim.net Müşteri’ye KOSGEB Girişimcilik Desteği programı
                (Program) için ön bilgilendirme yapacaktır. Alınması gereken
                eğitimler de dahil olmak üzere, Program’dan yararlanmak için
                sağlanması gereken tüm şartları yerine getirmek münhasıran
                Müşteri’nin sorumluluğunda olup, Emüşavirim.net’in bu hizmet
                kapsamındaki yükümlülüğü yalnızca Müşteri’ye ön bilgilendirme
                yapmaktan ibarettir. Program’dan yararlanmak için gerekli
                şartları sağlayan Müşteri’nin talebi halinde, Müşteri’nin
                Program’a başvurusuna ilişkin Emüşavirim.net tamamen kendi
                takdirinde olmak üzere ve Taraflar arasında kararlaştırılan
                koşullara uygun olarak danışmanlık verebilir.<br />
              </div>
            </div>
            <br />
            • <span class="font-semibold">4.3.</span>Müşteri, Emüşavirim.net’in
            faaliyetlerinin, kontrolü dışında gelişen sebepler veya burada
            sayılanlar ile sınırlı olmamak kaydıyla doğal afetler, kanuni
            engellemeler telekomünikasyon ve enerji altyapılarında geniş
            kapsamlı Ürün ve Hizmetlerin sunulmasını engelleyecek nitelikteki
            genel problemler, veri aktarımı ve bağlantı sorunları, Müşteri
            nezdinde kullanılmakta olan bilgisayar, ekipman ve donanımın iyi bir
            şekilde çalışmaması nedeniyle ya da kullanıcılarından kaynaklanan
            güvenlik problemleri sebebiyle geçici veya sürekli süreyle
            durdurulması veya hizmetlerin hiç veya gereği gibi verilememesi
            sebebiyle Emüşavirim.net’ten herhangi bir talepte bulunmayacaktır.
            Bu kapsamda Emüşavirim.net kaynaklı olmayan ve açıkça
            Emüşavirim.net’in kusurunun bulunmadığı hizmet kesintilerinden ve 3.
            kişiler tarafından yapılacak siber saldırıların yol açtığı
            zararlardan Emüşavirim.net hiçbir şekilde sorumlu
            tutulmayacaktır.<br /><br />
            •<span class="font-semibold"> 4.4. </span>Emüşavirim.net,
            Müşteri’den kaynaklanan gecikmeler ve kendisine yüklenemeyecek
            arıza, gecikme ve bunun gibi haberleşmeyi önleyici durumlara karşı
            sorumluluk kabul etmez.<br /><br />
            • <span class="font-semibold">4.5.</span>Emüşavirim.net, her türlü
            önlem ve tedbiri almış olmasına rağmen, kendisinin ulusal veya
            uluslararası yasalar, yetkili makamlarca yayımlanan tebliğ, genelge
            ve esaslar gereğince başka türlü hareket etmesini gerektirecek haklı
            nedenlerin mevcut olduğu durumlarda sunulan hizmetlerin kapsamını
            daraltabilir, tamamen durdurabilir. Müşteri, bu nedenlerle
            uğrayabileceği zarar ve kayıplar için hiçbir şekilde
            Emüşavirim.net’e rücu talebinde bulunmayacağını peşinen kabul, beyan
            ve taahhüt eder.<br /><br />
            •<span class="font-semibold"> 4.6. </span>Müşteri, Emüşavirim.net’in
            ve iş ortaklarının yükümlülüklerini yerine getirilebilmesi için
            Emüşavirim.net’e ve ilgili iş ortaklarına bildirmekle yükümlü olduğu
            her türlü bilgi ve belgeleri; doğru, eksiksiz, güncel ve tam olarak
            bildirecektir. Müşteri, Platform’da yer alan tüm bu bilgileri
            eksiksiz, tam ve güncel olarak sisteme girmekle ve Sözleşme
            süresince güncel tutmakla yükümlüdür. Aksi halde, hizmetin
            sunulamaması, hizmet sunumunun gecikmesi/aksaması, veya işbu bilgi
            ve belge eksikliklerinden doğrudan veya dolaylı kaynaklanan her
            türlü aksaklıktan doğrudan Müşteri sorumludur. Emüşavirim.net’in
            işbu sebeple uğradığı zararları tazmin etme hak ve yetkisi saklıdır
            ve/veya süreç içerisinde oraya çıkacak sıkıntılarda Emüşavirim.net
            sorumlu tutulamaz.<br /><br />
            • <span class="font-semibold">4.7. </span> Müşteri, başta KEP adresi
            ve diğer elektronik posta adresleri olmak üzere, Hizmet kapsamında
            Emüşavirim.net’e ibraz ettiği her türlü belge ve bilgilerin sürekli
            olarak güncel olmasından sorumludur. Müşteri, Emüşavirim.net’in
            herhangi bir e-mail adresinden, Kayıtlı Elektronik Posta adresinden
            ya da Müşteri’nin Platform’daki hesabına Emüşavirim.net tarafından
            gelecek bilgilendirme içerikli e-mailleri, duyuruları ve
            Emüşavirim.net’in Platform aracılığı ile yapacağı bildirimleri takip
            etmekle yükümlüdür. İbraz ettiği belge ve bilgilerde yanlışlık
            bulunması halinde veya bu belge ve bilgilerin mevzuata uygun
            olmadığının tespit edilmesi halinde Müşteri, herhangi bir tazminat
            ödeme yükümlülüğü altına girmeksizin Emüşavirim.net’in hizmeti
            durdurma ve/veya Sözleşme’yi feshetme hakkı olduğunu kabul, beyan ve
            taahhüt eder. Müşteri, hesap bilgilerinde meydana gelen
            değişiklikleri yazılı olarak Emüşavirim.net’e bildirmediği müddetçe,
            Emüşavirim.net var olan bilgiler doğrultusunda ilerleyecektir.<br /><br />
            • <span class="font-semibold">4.8.</span>Emüşavirim.net, Yetkili
            Kişiler’in kullanıcı adı ve şifreleri ile yapılacak olan işlemlerden
            doğacak olan yasal müeyyidelerden hiçbir sorumluluk kabul etmez.
            Yetkili Kullanıcılar’a tanınan yetkinin Müşteri tarafından geri
            çekilmesi durumunda ya da herhangi bir çalışanın yetkilendirilmemiş
            şekilde Platform’u kullanması durumunda bu hususu derhal
            Emüşavirim.net’e önce olabilecek en hızlı yöntemle ve ardından en
            kısa sürede yazılı olarak bildirmekle yükümlüdür., Emüşavirim.net
            talep üzerine ilgili kişinin yetkilerinin kaldırılmasını sistem
            üzerine tanımlayacak ve sisteme erişimini engelleyecektir.
            Müşteri’nin hali hazırdaki Yetkili Kullanıcılar üzerindeki yetkinin
            geri çekildiği veya yetkilerin değiştirildiğine dair
            Emüşavirim.net’e bildirimde bulunana kadar mevcut yetkilendirme
            geçerliliğini koruyacaktır. Müşteri’nin yetki değişikliklerini geç
            bildirmesinden, iflas etmesinden, tasfiye edilmesinden veya herhangi
            bir sebepten ötürü ticari faaliyetlerinin sona ermesinden dolayı
            Müşteri’ye ait "şifre" ve "yetkili kullanıcı bilgileri" nin
            kullanılması suretiyle işbu Sözleşme çerçevesinde yapılacak işlemler
            nedeniyle tüm sorumluluğun kendisine ait olduğunu ve
            Emüşavirim.net’in hiçbir sorumluluğu olmayacağını kabul, beyan ve
            taahhüt eder.<br /><br />
            •<span class="font-semibold"> 4.9.</span>Müşteri, Emüşavirim.net’in
            kendisine sunmuş olduğu yazılımları, donanımsal ve teknik altyapıyı
            zarara uğratacak veya bu yapıların sürekliliğini etkileyecek her
            türlü davranış ve tutumdan kaçınacağını kabul, beyan ve taahhüt
            eder.<br /><br />
            • <span class="font-semibold">4.10.</span>Müşteri, Muhasebe
            Hizmetleri’nin sunulabilmesi için, Muhasebe Hizmetleri’ni sunan
            Platform Kullanıcısı’nın aynı zamanda, Müşteri’nin Platform’daki
            Yetkili Kullanıcılar’ından biri olması sebebiyle, Platform’a
            sağladığı entegrasyon ile kendisinin Muhasebe Hizmetleri’ne ilişkin
            bilgilerini, ön muhasebe programı vasıtasıyla finansal verilerini
            görebileceğini, bu verilerden yararlanarak ve bu verileri kullanarak
            Muhasebe Hizmetleri’ni sunabileceğini, Platform Kullanıcısı’nın bu
            bilgi ve verilere ulaşmak için kendisinden ayrı bir izne/onaya
            ihtiyacı olmadığını, bu Sözleşme ile ilgili izin/onayın açıkça
            verildiğini kabul ve beyan eder.<br /><br />
            • <span class="font-semibold">4.11.</span>Bu Sözleşme aracılığıyla
            Müşteri’ye sunulan Ürün ve Hizmetler ile Müşteri’nin hak ve
            yükümlülüklerinin geçerliliği için, ilgili mevzuattaki istisnalar
            saklı kalmak kaydıyla, Müşteri’nin 18 yaşından büyük olması
            gerekmektir. Bu sebeple, Müşteri’nin bir gerçek kişi olması
            durumunda, Sözleşme’nin onaylanması ile Müşteri’nin 18 yaşından
            büyük olduğu kabul edilir. Müşteri’nin 18 yaşından küçük olması
            durumunda ise, bu Sözleşme’nin onaylanması halinde Müşteri’nin veli
            ve/veya vasisinden onay/izin aldığı varsayılacaktır.<br /><br />
            • <span class="font-semibold">4.12.</span> Emüşavirim.net
            Sözleşme’nin onaylanması ardından yapılacak değerlendirme sonucu
            Müşteri’nin onayladığı Sözleşme’ yi hukuki gerekçelerle veya gerekçe
            göstermeksizin reddetme hakkına da sahiptir. Bu kapsamda,
            Müşteri’nin Platform üzerinden Sözleşme’yi onaylaması ilgili mevzuat
            kapsamında “öneri/icap” olarak kabul edilir. Sözleşme’nin yürürlük
            ve hüküm doğurmaya başlaması için Emüşavirim.net’in aktif bir
            davranışı veya Sözleşme’nin kurulduğu gösterir zımni bildirimleri
            dikkate alınır.<br /><br />
            • <span class="font-semibold">4.13.</span>Müşteri, işbu Sözleşme
            konusu Hizmet’e ilişkin ödemesi gereken ücreti öderken bilgilerini
            girmiş olduğu kendisine ve/veya başkasına ait kredi/banka kartının
            geçerli bir banka kartı olduğunu, işbu ücret tutarlarının bu
            banka/kredi kartından tahsil edilmesi hususunda kendisinin/ kart
            sahibinin rızası dahilinde olduğunu kabul ve beyan eder. Müşteri,
            Sanal Cüzdan Hizmetleri kapsamında elde ettiği sanal kartı ile
            Emüşavirim.net’e ödeme yapabilecektir.<br /><br />
            • <span class="font-semibold">4.14.</span>Müşteri tarafından
            Emüşavirim.net nezdinde yetkilendirilmiş Yetkili Kullanıcılar Ürün
            ve Hizmetler’in sunulduğu Platform’u açma (bağlanma) bilgilerinin
            güvenliğinin sağlanması bakımından tüm önlemleri almak
            mükellefiyetindedir. Buna göre Yetkili Kullanıcı, kendisinden makul
            çerçevede beklenen her türlü önlemi alacaktır. Oturum açmak için
            kullanılan kullanıcı adı ve şifre Yetkili Kullanıcının şahsına özel
            ve başkalarına devredilemez mahiyettedir. Yetkili Kullanıcı bu
            bilgileri kendisi dışında hiçbir üçüncü kişi ile paylaşmamakla
            yükümlüdür.<br /><br />
            • <span class="font-semibold">4.15.</span>Müşteri ve Yetkili
            Kullanıcısı/Kullanıcıları, Oturum açma bilgilerinin üçüncü kişiler
            tarafından öğrenildiğini veya üçüncü kişiler tarafından
            kullanıldığını öğrenir veya bu durumdan şüphelenecek olursa, o zaman
            bunu derhal Emüşavirim.net’e bildirmekle yükümlüdür. Bu bildiriyi
            yapmakla Yetkili Kullanıcı, bunun farkına varmasının hemen akabinde
            Oturum açma bilgilerinde değişiklik yapma mükellefiyetlerinden
            kurtulmuş sayılmayacaktır. Bu halde Emüşavirim.net, suistimalleri
            önlemek bakımından derhal gerekli önlemleri alacaktır.<br /><br />
            • <span class="font-semibold">4.16.</span>Müşteri, işbu Sözleşme ve
            eklerinde belirtilen Hizmetler’den faydalanmak için gerekli olan
            teknik ve donanımsal altyapı gereksinimlerini sağlamakla yükümlü
            olduğunu kabul, beyan ve taahhüt eder.<br /><br />
            • <span class="font-semibold">4.17.</span>Taraflar, işbu
            Sözleşme’nin onaylanması ile birbirlerinin temsilcisi, acentesi,
            ticari mümessili, ortağı gibi unvan ve statüleri kazanmayacaklarını
            kabul, beyan ve taahhüt eder.<br /><br />
            • <span class="font-semibold">4.18.</span>Taraflar, Hizmet’e ilişkin
            yetkili makamlarca yayımlanan her türlü; yasa, tebliğ, genelge ve
            esaslar gereğince öngörülen yükümlülüklerin yerine getirilmesinden
            sorumludur. Müşteri, Başkanlık’ın Hizmet kapsamında yayımladığı;
            kılavuz, tebliğ, genelge dâhil ve bunlarla sınırlı olmaksızın her
            türlü dokümanı incelediğini, bu kurallardan haberdar olduğunu ve bu
            kurallara uygun davranacağını kabul, beyan ve taahhüt eder. Müşteri,
            Hizmet’in, Emüşavirim.net tarafından gerçekleştirebilmesi için
            Başkanlık nezdinde yapılması gereken tüm işlemlerden sorumludur.
            Müşteri, Sözleşme hükümlerinin yürürlüğe girdiği ana kadar Başkanlık
            nezdinde yaşanan sıkıntılardan münhasıran sorumlu olacağını kabul,
            beyan ve taahhüt eder. Müşteri’nin Başkanlık nezdinde
            gerçekleştirmesi gereken işlemlerden ve temin etmesi gereken
            belgelerden s Emüşavirim.net orumlu tutulamaz. Bundan bahisle,
            Müşteri’nin gerçekleştirmekle yükümlü olduğu işlem ve faaliyetlerde
            herhangi bir aksaklık/ gecikme veya imkansızlık meydana geldiğinde,
            işbu Sözleşme’de yer alan hizmetlerin Emüşavirim.net tarafından
            sunulmadığı Müşteri tarafından iddia edilemez.<br /><br />
            • <span class="font-semibold">4.19. </span> Emüşavirim.net, doğrudan
            kendisinin sunmadığı Muhasebe Hizmetleri ve e-İmza Hizmetleri gibi
            Ürün ve Hizmetler için, hizmetin kalitesi, müşteri memnuniyeti,
            ilgili hizmetin tam ve eksiksiz ifa edileceği dahil bu hizmetlere
            ilişkin herhangi bir konuda Müşteri’ye garanti veya taahhütte
            bulunmaz. Bununla birlikte Emüşavirim.net, Prim Ödeme Hizmeti ve
            Vergi Ödeme Hizmeti kapsamında yalnızca Müşteri tarafından kendisine
            bildirilen miktarları Müşteri tarafından kendisine bildirilen
            zamanlarda ödemekle yükümlü olduğundan, ilgili mevzuat nezdinde
            ödemelerin eksiksizliği ile ilgili Müşteri’ye herhangi bir garanti
            veya taahhütte bulunmaz.<br /><br />
            •<span class="font-semibold"> 4.20. </span>Uygulama’ya Dair Özel
            Hükümler<br />
            <div class="px-5 mt-5">
              <div>
                •<span class="font-semibold"> 4.20.1 </span> Uygulama
                aktivasyonu için talep edilen üyelik bilgilerinin, Müşteri’nin
                sağladığı cep telefonu numarasına gönderilecek tek kullanımlık
                aktivasyon kodunun ve Müşteri tarafından belirlenecek Uygulama
                şifresinin sağlanması gerekmektedir.<br /><br />
              </div>
              <div>
                •<span class="font-semibold"> 4.20.2 </span>Aktivasyon işlemini
                başarılı tamamlayan Müşteri için Uygulama ve cihaz eşleştirmesi
                yapılacak olup Müşteri sadece Uygulama şifresini girerek
                Uygulama’da işlem yapabilecektir.<br /><br />
              </div>
              <div>
                • <span class="font-semibold">4.20.3 </span>Uygulama şifresinin
                unutulması durumunda Uygulama içerisinden “Şifremi unuttum”
                seçeceği ile yeni bir şifre belirlenebilecektir.<br /><br />
              </div>
              <div>
                • <span class="font-semibold">4.20.4 </span>Emüşavirim.net
                ve/veya ilgili iş ortağı, Uygulama aktivasyonu sürecini ve
                Uygulama ile ilgili erişim adımlarını mevzuata uygun olmak
                kaydıyla dilediği zaman tek taraflı olarak değiştirme hakkına
                sahip olup bu halde Müşteri’nin Uygulama’yı kullanabilmesi için
                farklı/ek bilgi girişi yapması gerekebilecektir.<br /><br />
              </div>
              <div>
                • <span class="font-semibold">4.20.5 </span>Uygulama’ya tanımlı
                cep telefonunun değiştirilmesi, cep telefonunun kaybolması veya
                çalınması halinde Müşteri bunu Uygulama çağrı merkezi üzerinden
                Emüşavirim.net’e ve/veya ilgili iş ortağına bildirmekle
                yükümlüdür. Uygulama aktivasyonu, Uygulama Çağrı Merkezi’nden
                iptal edilebilecektir. Müşteri bu hallerde Emüşavirim.net’e
                bildirimde bulunmaması veya gerekli güncellemeleri yapmaması
                halinde tüm zararlardan münhasıran sorumlu olacak ve
                Emüşavirim.net’e bu kapsamda herhangi bir talep
                iletemeyecektir.<br /><br />
              </div>
              <div>
                • <span class="font-semibold">4.20.6 </span>Uygulama’nın
                kullanıldığı mobil cihazın tüm yazılımlarını (işletim sistemi
                dahil) güncel tutma ve mobil cihazı en güncel AntiVirüs ve
                AntiSpyWare programlarıyla yetkisiz erişimlere karşı koruma,
                şifre bilgilerine internet üzerinden kendisi dışındaki kişilerin
                erişmesini engellemek için mobil cihazın güvenliğini temin etme
                gibi mobil cihazın yetkisiz erişimlere karşı güvenliğine ilişkin
                teknik tedbirlerin alınması münhasıran Müşteri’nin
                sorumluluğudur. Böyle bir durumda Müşteri tüm zararlardan
                münhasıran sorumlu olacak ve Emüşavirim.net’e bu kapsamda
                herhangi bir talep iletemeyecektir.<br /><br />
              </div>
              <div>
                • <span class="font-semibold">4.20.7 </span>Müşteri, Uygulama
                üzerinden ödeme ve sair işlemlerden, Uygulama’yı
                kullanılmasından ve Uygulama’da paylaştığı her türlü bilgi ve
                içerikten münhasıran sorumlu olup paylaşılan bilginin içeriği ve
                doğruluğuna bağlı olarak ortaya çıkan her türlü talep ve zarar
                Emüşavirim.net tarafından Müşteri’ye iletilecek yahut rücu
                edilecektir.<br /><br />
              </div>
              <div>
                • <span class="font-semibold">4.20.8 </span>Müşteri, Uygulama’da
                yapılacak iyileştirme ve diğer değişikliklerin uygulanması için
                Uygulama’ya erişimin geçici olarak engellenebileceğini kabul
                eder. Yine aynı kapsamda Müşteri gerek Uygulama gerek mobil
                cihaz yazılım güncellemelerinin en son versiyonunu kullanmadığı
                durumlarda (güncellemelerin gerçekleştirilmediği hallerde)
                Uygulama’dan gerekli verimi alamaması, kesintiler olması, teknik
                aksaklılar yaşaması ve benzeri haller sebebiyle Emüşavirim.net’e
                karşı herhangi bir talepte bulunamayacağını kabul eder.<br /><br />
              </div>
            </div>
          </div>
        </div>
        <div class="flex-col">
          <div class="text-gray-900">(5) ÜCRETLENDİRME VE ÖDEME</div>
          <div class="text-gray-700 p-3">
            • <span class="font-semibold">5.1.</span>Bu Sözleşme kapsamında,
            aylık belirli ücret veya ilgili paketin belirlenmiş ücreti dışında,
            Muhasebesel Evrak Teslim Hizmetleri ve diğer Ürün ve Hizmetler için
            Müşteri’den farklı tutarda ücretler tahsil edilecektir. Müşteri’ye
            sunulan hizmetler için Müşteri’den tahsil edilecek ücretlerin
            detaylarına https://emusavirim.net/#packets’dan ulaşılabilir. İlgili
            tutarlar Müşteri’nin kredi kartından otomatik olarak tahsil edilecek
            olup; Müşteri’nin Emüşavirim.net tarafından belirlenen ve ilan
            edilen toplu bir paketten yararlanmak istemesi durumunda bu paketin
            toplam ücreti, Müşteri’nin aylık hizmet almak istemesi halinde
            ilgili ayın ücreti Müşteri’den peşin olarak ilgili döneme ilişkin
            Ürün ve Hizmetler sunulmaya başlanmadan önce tahsil edilecektir.
            <br />
            <br />
            • <span class="font-semibold">5.2.</span>Emüşavirim.net, kendi
            takdirinde olmak üzere bu Sözleşme’yi belirli bir süre (Taahhüt
            Süresi) içerisinde sonlandırmayacağını veya sonlandırılmasına
            sebebiyet vermeyeceğini taahhüt eden Müşteriler’den Hızlı
            Girişimcilik Paketi’ni tahsil etmeyecektir. Sözleşme’yi Taahhüt
            Süresi’nden önce sonlandıran veya sonlandırılmasına sebebiyet veren
            Müşteri, Hızlı Girişimcilik Paketi’nin Taahhüt Süresi içerisindeki
            ay sayısına bölünmesiyle hesaplanan tutarın, Sözleşme’nin sona
            erdiği tarih itibariyle Taahhüt Süresi’nden geriye kalan ay
            sayısıyla çarpılmasıyla hesaplanan tutarı, Emüşavirim.net’in ilk
            talebi üzerine derhal ödeyeceğini kabul ve taahhüt eder.
            Emüşavirim.net’in bu tutarı Sözleşme hükümlerine göre tahsil etme
            hakkı saklıdır. Emüşavirim.net, tamamen kendi takdirinde olmak üzere
            bu Sözleşme’nin 5.1 numaralı maddesi uyarınca, toplu bir paketten
            yararlanmak isteyen Müşteri’lerden de Hızlı Girişimcilik Paketi’ni
            tahsil etmeyebilir. Hızlı Girişimcilik Paketi’ne ve Taahhüt
            Süresi’ne ilişkin detaylar https://emusavirim.net/#packets adresinde
            bulunmaktadır. Muhasebe Hizmetleri için Müşteri’den tahsil edilecek
            tutar, Gelir İdaresi Başkanlığı tarafından ilgili yılda geçerli
            olmak üzere yayınlanan asgari ücret tarifesindeki asgari bedellerden
            az olmayacaktır . Hizmetlerin ücretlendirmesi Gelir İdaresi
            Başkanlığı veya ilgili mercilerin yayınladığı asgari ücret tarifesi
            uyarınca her yıl veya ilgili mercinin uygun gördüğü başka bir zaman
            aralığında değişiklik gösterebilir. Muhasebe Hizmetleri için
            Müşteri’den tahsil edilen işbu ücret, Müşteri’den tahsil edilen
            aylık ücretin içinde olup; <br />
            <br />
            • <span class="font-semibold">5.2. ve 5.3.</span> madde hükümleri
            saklıdır. <br />
            <br />
            • <span class="font-semibold">5.3.</span>Müşteri’den tahsil edilen
            sabit tutarlar dışında; Muhasebe Hizmetleri kapsamında, Gelir
            İdaresi Başkanlığı tarafından ilgili yıla ilişkin yayınlanan serbest
            muhasebecilik asgari ücret tarifesi kapsamındaki
            (https://www.ismmmo.org.tr/dosya/2131/Mevzuat-Dosya/04012021-asgariucret-2021.pdf)
            Tablo I ve Tablo II’de yer alan işlemler (bu tabloların değişiklik
            göstermesi halinde aylık baz ücret kapsamında olduğu ilgili idari
            merci tarafından ilan edilen ve/veya genel mesleki kurallar gereği
            bu tutara dahil olduğu bilinen işlemler) dışındaki işlemler ve
            tarifenin açıklayıcı notlarında ek ücrete tabi olduğu belirtilen
            işlemler için Müşteri’den ek ücret tahsil edilecektir. Müşteri bu
            hususu bildiğini, bu kapsam dışındaki işlemler için tahsil edilecek
            ek ücretlerin Platform’a tanımladığı kart hesabından otomatik olarak
            tahsil edilebileceğini kabul ve beyan eder. <br />
            <br />
            • <span class="font-semibold">5.4.</span>Müşteri’nin Ürün ve
            Hizmetler’den toplu bir paket olarak ya da aylık ücretler karşılığı
            yararlanması farketmeksizin, ilgili döneme/pakete ilişkin Müşteri’ye
            tanımlanan E-Dönüşüm Hizmetleri kontör sınırının doldurulması
            halinde, Müşteri, ek kontör satın almalıdır. E-Dönüşüm Hizmetleri
            için ek kontörler Platform üzerinden satın alınabilir. <br />
            <br />
            •<span class="font-semibold"> 5.5.</span>Müşteri, Ürün ve
            Hizmetler’in kendisine sunulmasını devam etmesi için önceki
            dönemlere ve ilgili döneme ilişkin ödemelerini tam ve eksiksiz
            yapmakla yükümlüdür. Aksi halde Emüşavirim.net’in Ürün ve
            Hizmetler’in sunumunu geçici/kalıcı durdurma veya iş ortaklarından
            durdurulmasını talep etme hakkı saklıdır. Müşteri’nin tahsilat için
            tanımladığı karttan otomatik tahsilatın gerçekleştirilemediği gün
            ödemeler eksik yapılmış kabul edilecek olup; ek süreye ihtiyaç
            bulunmamaktadır. Müşteri’nin ödemediği tutarlara ilişkin herhangi
            bir yasal takibe başvurulduğu anda, kendi yasal komisyon ücreti
            dışında Müşteri’den faiz, söz konusu yasal yollar için
            harcanan/harcanacak avukatlık ücretleri ve diğer masraflar talep
            edilecektir. <br />
            <br />
            •<span class="font-semibold"> 5.6. </span>Emüşavirim.net, Muhasebe
            Hizmetleri’ne ilişkin ücretlendirmeler hariç, Sözleşme’nin
            yenilendiği her yıl ve/veya kendisinin uygun gördüğü diğer
            zamanlarda, Müşteri’ye bu Sözleşme’de öngörülen yöntemlerle bildirim
            yaparak bu bedelleri değiştirme hakkına sahiptir. Bedel
            değişikliklerinin uygulanabilmesi için Müşteri’nin onayı gerekmez.
            Müşteri, Ürün ve Hizmetler’e ilişkin bir paket kullanmaktaysa bu
            paketinin tamamlanması ardından, Ürün ve Hizmetler’den aylık bedel
            ödeyerek yararlanıyorsa bedel değişikliğinin yapıldığı içinde
            bulunulan aydan sonraki ilk ücret tahsilatından itibaren (bu
            tahsilat dahil) bu değişen ücretlerle Ürün ve Hizmetler’den
            yararlanmaya devam edecektir. Muhasebe Hizmetleri’nde ücret
            değişikliklerine ilişkin Sözleşme’nin 5.2’inci maddesi saklıdır.
            <br />
            <br />
            • <span class="font-semibold">5.7. </span> Müşteri’den tahsil
            edilecek ödemeler, Emüşavirim.net’in birlikte çalıştığı ve ilgili
            mevzuat kapsamında izinli/ruhsatlı elektronik para ödeme kuruluşları
            tarafından gerçekleştirilecek olup Emüşavirim.net Müşteriler’in kart
            ve şifre bilgileri gibi bilgileri kendi altyapısında saklamaz. Sanal
            Cüzdan Hizmetleri hükümleri saklı olup; Emüşavirim.net Sanal Cüzdan
            üzerinden tahsis edilen sanal kart bilgilerini veya Sanal Cüzdan’a
            kendi kartlarını da bağlayabilir. Emüşavirim.net sanal kart dahil
            Müşteri’nin kart bilgilerini kendi bünyesinde saklamayacaktır.
            İlgili hizmeti sunan Emüşavirim.net iş ortağının sorumlulukları
            saklıdır ve bu sebeple Emüşavirim.net hiçbir şekilde sorumlu
            tutulamaz.
          </div>
        </div>
        <div class="flex-col">
          <div class="text-gray-900">(6) SÖZLEŞME’NİN SÜRESİ VE FESHİ</div>
          <div class="text-gray-700 p-3">
            •<span class="font-semibold"> 6.1. </span> Bu Sözleşme onay
            tarihinde yürürlüğe girer ve Taraflar’ca feshedilmediği müddetçe
            süresiz olarak yürürlükte kalır. Ancak bu Sözleşme’nin Ürün ve
            Hizmetler’e yönelik hükümleri, Müşteri’nin Paket’inin kapsamında
            bulunan ülkede ilgili Ürün ve Hizmetler’den yararlanması iradesini
            gösterdiği andan itibaren hüküm doğuracaktır. <br /><br />
            • <span class="font-semibold">6.2.</span>Ürün ve Hizmetler’den aylık
            yararlanan Müşteriler için Müşteri’nin bu Sözleşme’yi herhangi bir
            haklı neden olmaksızın feshetmek istemesi halinde, Sözleşme’nin
            feshedildiği tarihte içinde bulunulan ayın ücreti de Müşteri’den
            tahsil edilecektir. Ürün ve Hizmetler’den toplu bir paket satın
            alarak yararlanan Müşteriler için, Müşteri’nin bu Sözleşme’yi
            herhangi bir haklı neden olmaksızın feshetmek istemesi halinde
            ilgili paketin kalan dönemi de dahil o zamana kadar ödenen herhangi
            bir tutar Müşteri’ye iade edilmez.<br /><br />
            • <span class="font-semibold">6.3.</span> Emüşavirim.net’in bu
            Sözleşme’de yer alan esaslı yükümlülüklerinden birini ihlal etmesi
            halinde, Müşteri Emüşavirim.net’e 30 günlük süre tanıyarak işbu
            ihlale son vermesini ihtar eder. Bu süre sonunda ihlale son
            verilmemesi halinde, Müşteri işbu Sözleşme’yi haklı nedenle sona
            erdirebilecektir. Taraflar’ın ilgili mevzuattan doğan hakları
            saklıdır.<br /><br />
            • <span class="font-semibold">6.4.</span>Muhasebe Hizmetleri
            Sözleşmesi kapsamında meydana gelen herhangi bir durum veya ihlal bu
            Sözleşme’nin ihlal edildiği şeklinde yorumlanamaz ve bu Sözleşme’nin
            ihlaline sebebiyet vermez. Bu durumda Müşteri Emüşavirim.net ile bu
            Sözleşme’de belirtilen iletişim kanalları ile iletişime geçecek ve
            Emüşavirim.net de söz konusu durumun çözümü için elinden gelen
            gayreti sarfedecektir. Ancak her şartta Muhasebe Hizmetleri
            Sözleşmesi ve hükümleri bu Sözleşme’den ayrı ve bağımsızdır.<br /><br />
            • <span class="font-semibold">6.5.</span>Sebebi farketmeksizin, bu
            Sözleşme’nin sona ermesi halinde, Muhasebe Hizmetleri Sözleşmesi’nin
            Emüşavirim.net dışındaki üçüncü kişi iş ortağıyla akdedilmiş olması
            sebebiyle Müşteri işbu Muhasebe Hizmetleri Sözleşmesi’ni de sona
            erdirecektir. Bu doğrultuda Müşteri, Emüşavirim.net’in
            yönlendirmeleri ve bilgilendirmeleri doğrultusunda Muhasebe
            Hizmetleri Sözleşmesi’nin sona ermesi için gerekli bilgi ve
            belgeleri akdeder, ilgili formları imzalar ve/veya bunları ilgili
            üçüncü kişi iş ortağına gönderir/tebliğ eder.<br /><br />
            • <span class="font-semibold">6.6.</span>Sebebi farketmeksizin,
            Müşteri’ye Muhasebe Hizmetleri’ni sunan üçüncü kişi iş ortağının
            Emüşavirim.net ile ticari ilişkisinin sona ermesi halinde, Müşteri
            ile iş ortağı arasındaki Muhasebe Hizmetleri Sözleşmesi
            Emüşavirim.net’in uygun gördüğü başka bir Muhasebe Hizmetleri sunan
            üçüncü kişiye devredilecektir. Müşteri, bu durumda Emüşavirim.net’in
            uygun gördüğü kişilere Muhasebe Hizmetleri Sözleşmesi’nin
            devredilmesi için ilgili sözleşme devirlerini gerçekleştireceğini,
            bu süreçte Emüşavirim.net’in talimat ve yönlendirmeleri
            doğrultusunda hareket edeceğini ve ilgili belge/formları
            gecikmeksizin akdedeceğini, iş ortaklarına yapılması gereken
            bildirimleri Emüşavirim.net ile işbirliği içerisinde
            gerçekleştireceğini kabul ve beyan eder.<br /><br />
            •<span class="font-semibold"> 6.7.</span> Platform kapsamında
            akdedilen sözleşmelere herhangi bir aykırılık bulunmasa dahi,
            Müşteri’nin Platform’u kullanma amacının hukuka ve ahlaka aykırı
            olduğunun anlaşılması halinde, Emüşavirim.net ve iş ortakları,
            Müşterinin Sözleşmesi’ni sürecini kalıcı olarak sonlandırma ve
            Müşteri ile Platform kapsamında verilen Hizmetler’e ilişkin
            sözleşmelerin tamamını fesih hakkına sahiptir. Böyle bir durumda
            Emüşavirim.net’in sözleşmelerden ve kanundan doğan tazminat hakları
            saklıdır. Bu ihlal doğan/doğacak doğrudan dolaylı tüm zararlardan
            Müşteri sorumlu olup, Emüşavirim.net’in rücu hakkı saklıdır.<br /><br />
            •<span class="font-semibold"> 6.8.</span> Emüşavirim.net,
            Müşteri’nin bu Sözleşme’yi veya Platform üzerinden sunulan farklı
            hizmetlere ilişkin kuralları ve sözleşmeleri ihlal etmesi durumunda
            ve özellikle aşağıda sayılan hallerde, bu Sözleşme’yi tek taraflı ve
            tazminatsız herhangi bir bildirime gerek olmaksızın
            feshedebilecektir:<br />
            <div class="px-5">
              <span class="font-semibold"> a. </span> Müşteri’nin herhangi bir
              yöntem kullanarak, Platform’un işleyişini manipüle edecek
              davranışlarda bulunması,<br />
              <span class="font-semibold"> b. </span> Müşteri’nin Bu Sözleşme
              ve/veya diğer hizmet sözleşmelerini ihlal etmesi,<br />
              <span class="font-semibold"> c.</span> Müşteri’nin üçüncü
              kişilerin haklarını ihlal eden fillerde bulunması,<br />
              <span class="font-semibold"> d.</span> Müşteri’nin Platform’da
              veya Emüşavirim.net ile paylaştığı bilgi, içerik, görsel ve
              yazıların hukuka aykırı unsurlar taşıması veya hukuka ve ahlaka
              aykırı unsurlar taşımasa dahi Platform’da paylaşılan bilgi, içerik
              görsel ve yazıların hukuka ve ahlaka aykırı amaçlar ile
              paylaşılması.
            </div>
          </div>
        </div>
        <div class="flex-col">
          <div class="text-gray-900">(7) FİKRİ MÜLKİYET HAKLARI</div>
          <div class="text-gray-700 p-3">
            <span class="font-semibold"> a. 7.1.</span>Platform, Platform’daki
            her türlü yazılım ve kaynak kodları, tasarım, görsel, iş modeli,
            içerik, fotoğraf ve fikri ve/veya ticari hak doğuran/doğurabilecek
            tüm hususlara ilişkin her türlü fikri hak, mülkiyet ve menfaat
            Emüşavirim.net’e aittir. Platform’da, Sözleşme’de ve/veya
            içeriklerdeki herhangi bir hüküm bu hakların birinin/bir kısmının
            veya tamamının Müşteri’ye ait olduğu şeklinde yorumlanamaz. Bu
            Sözleşme kapsamında, Müşteri’nin Ürün ve Hizmetler’den yararlanmak
            üzere Platform’u kullanabilmesi için sadece Müşteri’ye özel, dünya
            çapında, alt lisans verilemez ve devredilemez, münhasır olmayan bir
            son kullanıcı lisansı tanınmaktadır. <br /><br />
            <span class="font-semibold"> b. 7.2.</span>Müşteri, Platform’u
            sadece bu Sözleşme’deki amaçlarla kullanabilir. Herhangi bir şekilde
            Platform’u, Platform’daki herhangi bir içeriği, bilgiyi veya görseli
            başka amaçla kullanamaz, kopyalayamaz, iletemez, tersine mühendislik
            işlemleri dahil Platform’un kaynak koduna veya dizinine ulaşmayı
            amaçlayan bir uygulama gerçekleştiremez.<br /><br />
            <span class="font-semibold"> c. 7.3.</span>Müşteri herhangi bir
            şekilde Emüşavirim.net ve/veya bağlı ortaklıklarının ticari
            unvanını, markasını, hizmet markasını, logosunu, alan adını vs.
            herhangi bir amaçla kullanamaz.
          </div>
        </div>
        <div class="flex-col">
          <div class="text-gray-900">(8) DİĞER HÜKÜMLER</div>
          <div class="text-gray-700 p-3">
            <span class="font-semibold"> a. 8.1.</span>Platform’da sunulan
            hizmetlerden faydalanabilmek amacıyla Müşteri olmak isteyen kişi
            Platform’un ilgili bölümündeki gerekli bilgileri doldurmalıdır.
            Platform’a Müşteri olurken Müşteri’nin verdiği e-posta adresinin ve
            diğer bilgilerin güncel olması zorunludur. Tüm yazışmalarda bu
            e-posta adresi esas alınır ve kullanılır. Gerekli bilgilerin
            iletilmesinin akabinde Emüşavirim.net tarafından kayıt işlemleri
            tamamlandığı takdirde kişi Müşteri olacaktır. <br /><br />
            <span class="font-semibold"> b. 8.2. </span>Emüşavirim.net yapacağı
            değerlendirme sonucu gerekçe göstermeksizin Müşteri olma başvurusunu
            reddetme hakkını haizdir. Emüşavirim.net’in Müşteri’nin iletmiş
            olduğu bilgilerin doğruluğunu araştırma yükümlülüğü bulunmamaktadır
            ve Müşteri’nin vermiş olduğu yanlış beyandan dolayı
            Emüşavirim.net’in herhangi bir sorumluluğu doğmayacaktır. Müşteri,
            Platform’a Müşteri olurken verdiği bilgilerin değişmesi halinde bu
            durumu gecikmeksizin Emüşavirim.net’e bildirmekle yükümlüdür.<br /><br />
            <span class="font-semibold"> c. 8.3</span>Bu Sözleşme’de ve
            Platform’da sunulan Hizmetler için belirtilen her türlü hak ve talep
            18 yaşından küçükler tarafından kullanılamaz. Bu sebeple,
            Müşterilerin gerçek kişi olması durumunda, Müşteri Sözleşme’yi
            onaylaması ile 18 yaşından büyük olduğunu kabul ve taahhüt eder.
            Müşterinin 18 yaşından küçük olması durumunda ise, bu Sözleşme’nin
            onaylanması halinde Müşterinin veli ve/veya vasisinden onay/izin
            aldığı varsayılacaktır. Müşterinin tüzel kişi olması halinde ise, bu
            Sözleşme’nin onaylanması ile Müşteri kendi yargı bölgesinde geçerli
            şekilde kurulmuş ve varlığını sürdürmekte olduğunu, bu Sözleşme’yi
            akdetmek dahil olmak üzere tüm iktidar ve yetkiye sahip olduğunu ve
            ayrıca Sözleşme’yi akdeden temsilcisinin Sözleşme’nin akdedilmesi
            için usulüne uygun yetkilendirildiğini kabul ve taahhüt eder.
            Emüşavirim.net’in tüzel kişi adına bu Sözleşme’yi akdeden gerçek
            kişinin temsil yetkisinin araştırma yükümlülüğü bulunmamaktadır.<br /><br />
            <span class="font-semibold"> d. 8.4</span>Müşteri, Emüşavirim.net’in
            yürürlükteki emredici mevzuat hükümleri gereğince ve ilgili
            Platform’da yayınlanan Gizlilik Politikası uyarınca resmi makamlara
            açıklama yapmakla yükümlü olduğu durumlar içerisinde, resmi
            makamlarca usulü dairesinde bu bilgilerin talep edilmesi halinde
            Müşterilere ait gizli/özel/ticari bilgileri resmi makamlara
            açıklamaya yetkili olacağını ve bu sebeple Emüşavirim.net’den her ne
            nam altında olursa olsun tazminat talep edilemeyeceğini kabul ve
            taahhüt eder.<br /><br />
            <span class="font-semibold">e. 8.5</span>Müşteri’nin Platform
            aracılığıyla sunulan hizmetlerden yararlanabilmek amacıyla
            kullandığı sisteme erişim araçlarının (kullanıcı adı, şifre v.b)
            güvenliği, saklanması, üçüncü kişilerin bilgisinden uzak tutulması
            ve kullanılmasına ilişkin hususlar tamamen Müşteri’nin
            sorumluluğundadır. Müşteriye ait kullanıcı adı ve şifre ile
            gerçekleştirilen her işlem bizzat Müşteri tarafından gerçekleşmiş
            sayılacaktır. Müşteri’nin, sisteme ve Platform’a giriş araçlarının
            güvenliği, saklanması, üçüncü kişilerin bilgisinden uzak tutulması,
            kullanılması gibi hususlardaki tüm ihmal ve kusurlarından dolayı
            Müşterilerin ve/veya üçüncü kişilerin uğradığı veya uğrayabileceği
            zararlardan Emüşavirim.net’in doğrudan veya dolaylı herhangi bir
            sorumluluğu yoktur. Emüşavirim.net’in rücu hakkı saklıdır.<br /><br />
            <span class="font-semibold"> f. 8.6.</span>Müşteri, Platform
            dahilinde kendileri tarafından sağlanan bilgi ve içeriklerin doğru
            ve hukuka uygun olduğunu kabul, beyan ve taahhüt eder.
            Emüşavirim.net Müşteri tarafından kendisine iletilen veya Platform
            üzerinden Müşteri tarafından yüklenen, değiştirilen veya sağlanan
            bilgi ve içeriklerin doğruluğunu araştırma, bu bilgi ve içeriklerin
            güvenli, doğru ve hukuka uygun olduğunu taahhüt ve garanti etme
            yükümlülüğü yoktur. Bu sebeple, söz konusu bilgi ve içeriklerin
            yanlış, hukuka aykırı veya hatalı olmasından dolayı doğan/doğacak,
            maddi/manevi, doğrudan ya da dolaylı tüm zararlardan kusuru olmasa
            dahi Müşteri tek başına sorumludur. Emüşavirim.net’in rücu hakkı
            saklıdır.<br /><br />
            <span class="font-semibold"> g. 8.7.</span>Bu Sözleşme’de veya
            Platform’daki hizmetler için Emüşavirim.net ya da İş Ortakları ile
            akdedilecek sözleşmelerde yer alan özel sorumluluk hükümleri saklı
            kalmak kaydıyla, Müşteri Platform üzerinden gerçekleştirdiği iş ve
            işlemlerin hukuka uygun olacağını ve hukuka aykırı her türlü
            iş/işlem sonucunda doğan zarardan münhasıran sorumlu olacağını kabul
            eder.<br /><br />
            <span class="font-semibold"> h. 8.8.</span>Müşteri, Emüşavirim.net
            ve İş Ortakları’nın ayni veya şahsi haklarını, kişilik haklarını,
            fikri mülkiyet haklarını, veya malvarlığı haklarını ve fikri
            mülkiyet haklarına konu olabilecek mal veya hizmetlere ilişkin
            herhangi bir hakkı ihlal teşkil edecek şekilde, Platform dahilinde
            bulunan resimleri, metinleri, görsel ve işitsel imgeleri, dosyaları,
            yazıları, istatistikleri, bilgilendirmeleri, içerikleri, veri
            tabanlarını, katalogları ve listeleri çoğaltmayacağını,
            kopyalamayacağını, yaymayacağını, dağıtmayacağını, aktarmayacağını
            işlemeyeceğini kabul beyan ve taahhüt eder. Müşteri gerek bu
            eylemleri ile gerekse de başka yollarla Emüşavirim.net ile doğrudan
            ve/veya dolaylı olarak rekabete girmeyeceğini ve Emüşavirim.net’i bu
            yollarla herhangi bir zarara uğratmayacağını kabul, beyan ve taahhüt
            eder.<br /><br />
            <span class="font-semibold"> i. 8.9 </span>Emüşavirim.net, Platform
            üzerinde sunulan Hizmetler’de ve içeriklerde değişiklik yapma
            hakkını saklı tutar. Müşteri bu değişiklikler veya iptallerden
            dolayı uğradığı/uğrayacağı doğrudan ve/veya dolaylı zararlar için
            her ne nam altında olursa olsun Emüşavirim.net’den tazminat talep
            edemeyeceğini kabul ve beyan eder.<br /><br />
            <span class="font-semibold"> j. 8.10.</span>Grev, lokavt, siber
            saldırı, savaş, iç savaş, terör eylemleri, deprem, yangın, sel,
            benzeri tabii afetler ve benzeri haller gibi Taraflar’ın kontrolü
            dışında gelişen ve Taraflar’ın bu Sözleşme ile yüklendiği borçlarını
            yerine getirmelerini engelleyici ve/veya geciktirici hallerin
            meydana gelmesi mücbir sebep hali olarak değerlendirilir. Herhangi
            bir Taraf, kendi kontrolünde olmayan bu sayılanlar veya benzeri
            mücbir sebep hallerinde, mücbir sebep sürdüğü sürece
            yükümlülüklerini tam veya zamanında yerine getirememekten sorumlu
            tutulamaz. İşbu durumun 90 (doksan) günden fazla sürmesi halinde
            diğer Taraf mücbir sebepten etkilenen Taraf’a Türk Ticaret
            Kanunu’nun 18(3)üncü maddesi uyarınca bir bildirimde bulunmak
            kaydıyla Sözleşme’yi feshetme hakkına sahiptir. Mücbir sebep hali
            sebebiyle yükümlülüklerini yerine getiremeyen Taraf, haber vermesi
            kendinden beklenebilecek en kısa sürede bu durumu diğer Taraf’a
            yazılı olarak bildirir.<br /><br />
            <span class="font-semibold"> k. 8.11.</span>Emüşavirim.net, Müşteri
            ile Müşteri’nin kayıt olurken bildirdiği elektronik posta adresleri
            vasıtasıyla veya Platform’da yer alan genel bilgilendirme
            aracılığıyla iletişim kurulacaktır. Bu şekilde yapılan
            bilgilendirmeler ve Sözleşme değişiklikleri Emüşavirim.net için
            yazılı iletişimin yerini tutar. Müşteri bu kapsamda elektronik posta
            adresini güncel tutmak ve Platform’u bilgilendirmeler için düzenli
            kontrol etmekle yükümlüdür.<br /><br />
            <span class="font-semibold"> l. 8.12.</span>Müşteri, bu Sözleşme’den
            doğan alacak, hak ve yükümlülüklerini, Emüşavirim.net’in ön yazılı
            izni olmaksızın, üçüncü kişilere devir ve temlik edemez. Aksi halde,
            Emüşavirim.net bu Sözleşme’yi derhal ve haklı nedenle sona
            erdirebilir.<br /><br />
            <span class="font-semibold"> m. 8.13</span>İşbu Sözleşme’nin 1.
            maddesinde yer verilen adresler tarafların Tebligat Kanunu’na göre
            geçerli tebligat adresleri olup, Taraflar herhangi bir adres
            değişikliğinde, bu değişiklik diğer Taraf’a gecikmeksizin yazılı
            veya e-posta yoluyla bildirilecektir, Emüşavirim.net Platform’da
            ilan ederek veya İletişim bölümündeki bilgilerini güncelleyerek de
            ilgili bildirimi gerçekleştirebilir. Aksi halde bu Sözleşme’de
            yazılı adreslere yapılacak tebligatlar usulüne uygun olarak yapılmış
            sayılacaktır. Adres değişikliklerinin zamanında bildirilmemesinden
            doğacak her türlü sorumluluk, bildirimi yapmayan Taraf’a ait
            olacaktır.<br /><br />
            <span class="font-semibold"> n. 8.14</span>İşbu Sözleşme ve ekleri
            kapsamında Emüşavirim.net’in ticari defter ve kayıtları, bilgisayar
            kayıtları ile Taraflar’ca düzenlenecek 6100 sayılı Hukuk
            Muhakemeleri Kanunu kapsamındaki her türlü belge, tutanak, rapor,
            makbuz, fakslar ve fakslara ait kayıtlar, e-posta ve benzeri
            evraklar idari veya adli yargı organları nezdinde aksi ileri
            sürülemez kesin delil sözleşmesi niteliğindedir.<br /><br />
            <span class="font-semibold">o. 8.15 </span>Bu Sözleşme, Türkiye
            Cumhuriyeti kanunlarına tabidir. İşbu Sözleşme’nin yorumlanmasından
            veya uygulanmasından doğabilecek tüm uyuşmazlıklar, İstanbul
            (Çağlayan) Mahkemeleri ve İcra Daireleri münhasıran yetkilidir.<br /><br />
            <span class="font-semibold"> p. 8.16 </span> Bu Sözleşme Platform
            üzerinden, Müşteri tarafından onaylanarak online olarak akdedilmiş
            olup; Sözleşme Müşteri’ye özel Platform’un ilgili sekmesinden
            ulaşılabilir durumdadır.
          </div>
        </div>
        <div class="flex-col">
          <div class="text-gray-900">(9) TİCARİ ELEKTRONİK İLETİ</div>
          <div class="text-gray-700 p-3">
            • Emüşavirim.net, Müşteri’nin kendisiyle paylaştığı cep telefonu
            numarası, elektronik posta adresi dahil Müşteri’nin iletişim
            bilgileri üzerinden Emüşavirim.net tarafından yürütülen kampanyalar,
            avantajlar, promosyonlar, tanıtımlar, reklâm, anketler, araştırmalar
            ve diğer müşteri memnuniyeti uygulamalarını sunmak, bunlar hakkında
            bilgi vermek dahil olmak üzere türlü amaçla Müşteri ile iletişime
            geçebilir. Müşteri bu kapsamda kendisiyle iletişime geçilmesine izin
            vermektedir. Müşteri, kendisiyle bu kapsamda iletişime geçilmesini
            istememesi halinde, Emüşavirim.net ile iletişime geçmelidir.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/website/Footer";
import { PopoverButton, Popover, PopoverPanel } from "@headlessui/vue";
import { MenuIcon } from "@heroicons/vue/outline";
export default {
  components: {
    Footer,
    PopoverButton,
    MenuIcon,
    Popover,
    PopoverPanel,
  },
};
</script>
<style scoped lang="scss">
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .app-container {
    max-width: 441px;
  }
}

.pr-20 {
  padding-right: 20px;
}

.bg-gray {
  background: #ededed;
}

.display-none {
  display: none;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
