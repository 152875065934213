<template>
  <div class="relative pt-6 pb-6 bg-gray-800">
    <Popover>
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <nav
          class="relative flex items-center justify-between sm:h-10 md:justify-center"
          aria-label="Global"
        >
          <div
            class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0"
          >
            <div class="flex items-center justify-between w-full md:w-auto">
              <a href="/">
                <span class="sr-only">Emusavirim</span>
                <img
                  class="h-12"
                  src="../../public/img/e-musavirim.png"
                  alt="Workflow"
                />
              </a>
              <div class="-mr-2 flex items-center md:hidden">
                <PopoverButton
                  class="bg-gray-600 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span class="sr-only">Open main menu</span>
                  <MenuIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
          </div>
          <div class="hidden md:flex md:space-x-10">
            <a
              v-for="item in navigation"
              :key="item.name"
              :href="item.href"
              class="font-medium text-white hover:text-gray-300"
              >{{ item.name }}</a
            >
          </div>
          <div
            class="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0"
          >
            <span class="inline-flex rounded-md shadow">
              <a
                href="/app/login"
                class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
              >
                {{ $t("message.myAccount") }}
              </a>
            </span>
          </div>
        </nav>
      </div>
      <transition
        enter-active-class="duration-150 ease-out"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="duration-100 ease-in"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95"
      >
        <PopoverPanel
          focus
          class="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div
            class="rounded-lg shadow-md bg-gray-700 ring-1 ring-black ring-opacity-5 overflow-hidden"
          >
            <div class="px-5 pt-4 flex items-center justify-between">
              <div></div>
              <div class="-mr-2">
                <PopoverButton
                  class="bg-gray-600 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="px-2 pt-2 pb-3">
              <a
                v-for="item in navigation"
                :key="item.name"
                :href="item.href"
                class="block px-3 py-2 rounded-md text-base font-medium text-gray-100 hover:text-gray-500 hover:bg-gray-50"
                >{{ item.name }}</a
              >
            </div>
            <a
              href="/app/login"
              class="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-100 hover:bg-gray-200"
            >
              {{ $t("message.login") }}
            </a>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
  <div class="max-w-7xl mx-auto p-4 sm:px-6 lg:px-8">
    <div class="max-w-7xl flex items-center justify-center space-y-5 p-5">
      <div class="flex-col">
        <div
          class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
        >
          Çerez Politikası
        </div>
        <div class="flex-col">
          <div class="text-lg font-medium text-gray-900 mb-3 mt-7"></div>
          <div class="text-gray-700">
            Emüşavirim.net (“Emüşavirim.net”) tarafından işletilen “https://
            Emüşavirim.net internet sitesini (“Site”) ziyaret edenlerin (“Veri
            Sahibi”) gizliliğini korumak Emüşavirim.net’in önde gelen
            ilkelerinden olup gizlilik ve güvenlik haklarınız temel
            prensibimizdir. Müşterilerimizin kişisel bilgilerinin gizliliğini
            korumak amacıyla sistem ve internet altyapımız en güvenilir seviyede
            tutularak gerekli önlemler alınmıştır. Çerez Politikasının
            (“Politika”) amacı, tarafımızca işletilmekte olan sitenin
            kullanıcıları/ ziyaretçileri (“İlgili Kişi”) tarafından ziyaret
            edilmesi ile elde edilen çerezlerin, türleri ve kullanım amaçları
            hakkında ilgili sizleri bilgilendirmektir.
          </div>
        </div>
        <div class="flex-col">
          <div class="text-lg font-medium text-gray-900 mb-3 mt-7">
            1.1 Çerez Politikası Hakkında
          </div>
          <div class="text-gray-700">
            Sitemizi şahsi herhangi bir bilgi vermeden ziyaret edebilir,
            ürünlerimiz ve servislerimiz hakkında bilgi alarak ihtiyaçlarınızı
            karşılayabilirsiniz. Ziyaretlerde site kullanımına ilişkin bilgi
            toplamak için çerez kullanılmaktadır. Buradaki amacımız; sitemizi
            ziyaret eden kullanıcıya kolaylık sağlamak ve işleyişi daha iyiye
            nasıl taşıyabileceğimizi anlamaktır. Çerez Politikamız, çerezlerin
            ne olduğunu ve bunları nasıl kullandığımızı açıklar. Çerezlerin ne
            olduğunu, bunları nasıl kullandığımızı, kullandığımız çerez
            türlerini, çerezleri kullanarak topladığımız bilgileri ve bu
            bilgilerin nasıl kullanıldığını ve çerez tercihlerini nasıl kontrol
            edileceğinizi anlamak için Çerez Politikamızı okumalısınız. Kişisel
            verilerinizi nasıl kullandığımız, sakladığımız ve sakladığımız
            hakkında daha fazla bilgi için,
            <a
              class="text-blue-500"
              @click="
                navigation[0].current = true;
                navigation[1].current = false;
                navigation[2].current = false;
              "
              >Gizlilik Politikamıza</a
            >
            göz atabilirsiniz. İstediğiniz zaman sitemizdeki pop-up olarak
            sunulan çerez bildiriminde vermiş olduğunuz onayınızı değiştirebilir
            veya geri çekebilirsiniz. <br />
            İzniniz aşağıdaki alanlara uygulanır: <br />
            1.
            <a
              class="text-blue-500"
              href="https://emusavirim.net"
              target="_blank"
            >
              https://emusavirim.net </a
            ><br />
            2.
            <a
              class="text-blue-500"
              href="https://www.google.com.tr/"
              target="_blank"
            >
              https://www.google.com.tr/ </a
            ><br />
            3.
            <a
              class="text-blue-500"
              href="https://www.facebook.com/"
              target="_blank"
            >
              https://www.facebook.com/</a
            ><br />
            4.
            <a
              class="text-blue-500"
              href="https://www.instagram.com/?hl=tr"
              target="_blank"
              >https://www.instagram.com/?hl=tr</a
            ><br />
          </div>
        </div>
        <div class="flex-col">
          <div class="text-lg font-medium text-gray-900 mb-3 mt-7">
            1.2 Çerez Nedir?
          </div>
          <div class="text-gray-700">
            Çerez, bir web sitesini ziyaret ettiğinizde bilgisayarınıza veya
            mobil cihazınıza (“cihaz”),kaydettiği bir dizi karakter içeren küçük
            bir dosyadır. Site tarayıcınıza yüklendiğinde, çerezler cihazınızda
            saklanır. Çerezler bir web sitesinin daha kullanıcı dostu deneyim
            sunmasını amaçlar. Çerezler, sitemizin düzgün çalışmasını
            sağlamamıza, sitemizi daha güvenli hale getirmemize, daha iyi bir
            kullanıcı deneyimi sunmamıza, sitemizin nasıl performans
            gösterdiğini, neyin işe yaradığını görmemizi ve iyileştirilmesi
            gereken yerleri analiz etmemizi sağlar. Kısaca kişisel
            ihtiyaçlarınıza daha uygun ve hızlı bir ziyaret deneyimi yaşatmak
            için kişiselleştirilmiş web sayfaları sunulabilmesine olanak
            vermektedir. Çerezler bilgisayarınızda veya dosyalarınızda depolanan
            kişisel verileriniz de dahil herhangi bir bilgi toplayamamaktadır.
            İnternet sitemizden en verimli şekilde faydalanabilmeniz ve
            kullanıcı deneyiminizi geliştirebilmek için çerez kullanıyoruz.
            Çerez kullanılmasını tercih etmezseniz tarayıcınızın ayarlarından
            çerezleri silebilir ya da engelleyebilirsiniz. Ancak bunun site
            kullanımınızı etkileyebileceğini hatırlatmak isteriz.
          </div>
        </div>
        <div class="flex-col">
          <div class="text-lg font-medium text-gray-900 mb-3 mt-7">
            1.3 Çerezleri Nasıl Kullanıyoruz?
          </div>
          <div class="text-gray-700">
            Sitemizde kullanılan üçüncü taraf çerezleri, temel olarak sitenin
            nasıl performans gösterdiğini belirlemek, sitemizle nasıl
            etkileşimde bulunduğunuzu tespit etmek, istatiksel analizler yapmak,
            güvenlik önlemleri almak, hizmetlerimizi güvende tutmak, reklamlar
            sunmak ve hepsinde size daha iyi bir hizmet sunmak için kullanılır.
            Kullanıcı deneyimi ve sitemizle gelecekteki etkileşimlerinizi
            hızlandırmaya yardımcı olur. Sitemizde çerez kullanılmasının başlıca
            amaçları aşağıda sıralanmaktadır: – İnternet sitesinin
            işlevselliğini ve performansını arttırmak yoluyla sizlere sunulan
            hizmetleri geliştirmek, – İnternet sitesini iyileştirmek ve internet
            sitesi üzerinden yeni özellikler sunmak ve sunulan özellikleri
            sizlerin tercihlerine göre kişiselleştirmek, – İnternet sitesinin,
            sizin ve Emüşavirim.net’in hukuki ve ticari güvenliğinin teminini
            sağlamak.
          </div>
        </div>
        <div class="flex-col">
          <div class="text-lg font-medium text-gray-900 mb-3 mt-7">
            1.4 Ne tür çerezler kullanıyoruz?
          </div>
          <div class="text-gray-700">
            Çevrimiçi hizmetlerin çoğu gibi, sitemizde bir dizi amaç için
            birinci ve üçüncü taraf çerezleri kullanır. Birinci taraf çerezleri
            çoğunlukla sitenin doğru şekilde çalışması için gereklidir ve
            kişisel olarak tanımlanabilir verilerinizin hiçbirini toplamazlar.
            <br />
            <span class="font-semibold">Temel Çerezler:</span> Sitemizin tüm
            işlevselliğini tecrübe edebilmeniz için bazı çerezler zorunludur.
            Kullanıcı oturumlarını sürdürmemize ve güvenlik tehditlerini
            önlememize sağlarlar. Kişisel bilgiler toplamaz veya saklamazlar.
            <br />

            <span class="font-semibold"> İstatistiksel Çerezler:</span>Bu
            çerezler, web sitesine gelen ziyaretçi sayısı, benzersiz ziyaretçi
            sayısı, web sitesinin hangi sayfalarının ziyaret edildiği, ziyaretin
            kaynağı vb. gibi bilgileri depolar. Bu veriler, web sitesinin ne
            kadar iyi performans gösterdiğini analiz etmemize yardımcı olur. Bu
            çerezlerde depolanan bilgiler, üçüncü taraf reklam sağlayıcıları
            tarafından, tarayıcıdaki diğer web sitelerinde reklam göstermek için
            de kullanılabilir.
            <br />

            <span class="font-semibold"> İşlevsel ve Analitik Çerezler:</span
            >Siteye nereden bağlandığınız, site üzerinde hangi içeriği
            görüntülediğiniz ve ziyaretinizin süresi gibi siteyi kullanımınızın
            izlenmesi dahil olmak üzere, siteyi nasıl kullandığınızı belirlemek
            için kullanılan çerezlerdir.
            <br />

            <span class="font-semibold"> Reklam ve Pazarlama Çerezleri:</span>Bu
            çerezler, göz attığınız, satın aldığınız veya ilgi gösterdiğiniz
            bilgilere göre size özel pazarlama ve reklam sunmasına yardımcı
            olmak veya bir reklamı kaç kez gördüğünüzü sınırlamak için çevrimiçi
            etkinliğinizi izler. Bu reklamları hedeflemek için bu çerezleri
            diğer kullanıcılarla gruplandırmaya olanak veren araçlar sağlayan
            üçüncü taraf kuruluşlarla birlikte kullanılır.
            <br />

            <span class="font-semibold"> Otantikasyon Çerezleri:</span
            >Ziyaretçiler, şifrelerini kullanarak internet sitesine giriş
            yapmaları durumunda, bu tür çerezler ile ziyaretçinin internet
            sitesinde ziyaret ettiği her bir sayfada site kullanıcısı olduğu
            belirlenerek, kullanıcının her sayfada şifresini yeniden girmesi
            önlenir.
            <br />

            <span class="font-semibold"> Tercihler:</span>Bu çerezler
            ayarlarınızı kaydetmemize ve dil tercihleri gibi tarama
            tercihlerinizi belirlememize yardımcı olur, böylece sitemize
            gelecekteki ziyaretlerinizde daha iyi ve verimli bir deneyime sahip
            olursunuz.
            <br />
            Çerezler, geçerlilik sürelerine göre Kalıcı Çerez ve Geçici Çerez
            olarak iki çerez tipi bulunmaktadır. Geçici çerezler internet
            sitesini ziyaret ettiğiniz esnada oluşturulur ve sadece tarayıcınızı
            kapatıncaya kadar geçerlidirler. Kalıcı çerezler ise internet
            sitesini ziyaret ettiğinizde oluşturulur ve siz silinceye veya
            süreleri doluncaya kadar kalırlar. Ayarlarınız ile uyumlu
            kişiselleştirilmiş bir deneyim sunma gibi işlemler yapmak için
            kalıcı çerezler kullanılırlar.
            <br />
            Sitemizde yer alan geçici ve kalıcı tip çerezler, sizi sitemizin
            diğer kullanıcılarından ayırt etmek, ilgi alanlarınıza ve
            tercihlerinize göre düzenleme yapmak için
            kullanılmaktadır.Emüşavirim.net tarafından tasarlanmış çerezlerin
            yanı sıra üçüncü taraflardan alınan hizmetler kapsamında da çerez
            kullanılabilmektedir.
            <table>
              <thead>
                <tr>
                  <th scope="col">Çerez</th>
                  <th scope="col">Tanım</th>
                  <th scope="col">Amaç</th>
                  <th scope="col">Süre</th>
                  <th scope="col">Tür</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row" data-label="Çerez">test_cookie</td>
                  <td data-label="Tanım">
                    doubleclick.net tarafından sağlanan <br />bu çerezin amacı
                    ziyaretçinin kullanmış olduğu tarayıcının çerezleri
                    destekleyip desteklemediğini belirlemektedir.
                  </td>
                  <td data-label="Amaç">İşlevsel</td>
                  <td data-label="Süre">15 dakika</td>
                  <td data-label="Tür">Reklam ve Pazarlama</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Çerez">
                    _gat_gtag_UA_38734931_1
                  </td>
                  <td data-label="Tanım">
                    Google ziyaretçileri <br />
                    ayırt etmek için bu çerezleri kullanmaktadır.
                  </td>
                  <td data-label="Amaç">İşlevsel, Pazarlama</td>
                  <td data-label="Süre">1 dakika</td>
                  <td data-label="Tür">Analitik</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Çerez">utm_medium</td>
                  <td data-label="Tanım">
                    Reklam kaynaklarını <br />
                    izlemek için kullanılmaktadır.
                  </td>
                  <td data-label="Amaç">İşlevsel, Pazarlama</td>
                  <td data-label="Süre">7979 yıl</td>
                  <td data-label="Tür">Analitik</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Çerez">utm_campaign</td>
                  <td data-label="Tanım">
                    Reklam kaynaklarını <br />izlemek için kullanılmaktadır.
                  </td>
                  <td data-label="Amaç">İşlevsel, Pazarlama</td>
                  <td data-label="Süre">7979 yıl</td>
                  <td data-label="Tür">Analitik</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Çerez">utm_content</td>
                  <td data-label="Tanım">
                    Reklam kaynaklarını <br />
                    izlemek için kullanılmaktadır.
                  </td>
                  <td data-label="Amaç">İşlevsel, Pazarlama</td>
                  <td data-label="Süre">7979 yıl</td>
                  <td data-label="Tür">Analitik</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Çerez">utm_term</td>
                  <td data-label="Tanım">
                    Reklam kaynaklarını <br />izlemek için kullanılmaktadır.
                  </td>
                  <td data-label="Amaç">İşlevsel, Pazarlama</td>
                  <td data-label="Süre">7979 yıl</td>
                  <td data-label="Tür">Analitik</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Çerez">utm_source</td>
                  <td data-label="Tanım">
                    Reklam kaynaklarını <br />izlemek için kullanılmaktadır.
                  </td>
                  <td data-label="Amaç">İşlevsel, Pazarlama</td>
                  <td data-label="Süre">7979 yıl</td>
                  <td data-label="Tür">Analitik</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Çerez">_fbp</td>
                  <td data-label="Tanım">
                    Facebook tarafından <br />
                    sağlanan bir çerez <br />olup sitenin ziyaret edilmesinin
                    ardından ziyaretçiye Facebook’ta ya da Facebook reklamıyla
                    desteklenen dijital bir platformda olduklarında reklam
                    vermek için kullanılmaktadır.
                  </td>
                  <td data-label="Amaç">Pazarlama</td>
                  <td data-label="Süre">2 ay</td>
                  <td data-label="Tür">Reklam ve Pazarlama</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Çerez">fr</td>
                  <td data-label="Tanım">
                    Facebook tarafından <br />
                    kullanıcılara benzer <br />reklamlar göstermek reklamları
                    ölçmek ve geliştirmek için sağlanan bir çerezdir. Bu çerez
                    aynı zamanda Facebook pikseli veya Facebook sosyal eklentisi
                    olan sitelerde kullanıcının internet üzerindeki davranışını
                    izler.
                  </td>
                  <td data-label="Amaç">Pazarlama</td>
                  <td data-label="Süre">2 ay</td>
                  <td data-label="Tür">Reklam ve Pazarlama</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Çerez">_gcl_au</td>
                  <td data-label="Tanım">
                    Google Analytics <br />
                    tarafından kullanılan bir çerez türü olup site ile ziyaretçi
                    etkileşimlerini anlamak için kullanılır.
                  </td>
                  <td data-label="Amaç">İstatiksel</td>
                  <td data-label="Süre">2 ay</td>
                  <td data-label="Tür">Analitik</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Çerez">_gid</td>
                  <td data-label="Tanım">
                    Google Analytics <br />
                    tarafından kullanılan <br />
                    bir çerez türüdür. <br />Bu çerez ziyaretçilerin siteyi
                    nasıl kullandıkları hakkında analitik rapor oluşturmak için
                    kullanılmaktadır.
                  </td>
                  <td data-label="Amaç">İstatiksel, Pazarlama</td>
                  <td data-label="Süre">1 gün</td>
                  <td data-label="Tür">Analitik</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Çerez">_ga</td>
                  <td data-label="Tanım">
                    Google Analytics <br />
                    tarafından kullanılan <br />bir çerez türüdür. <br />
                    Bu çerez kullanıcı, <br />
                    oturum, kampanya <br />
                    verisi ve site kullanımının <br />
                    site için analiz edilerek raporlanması amacıyla
                    kullanılmaktadır. Bu çerez ziyaretçilerin kimliklerini
                    tespit etmeden bilgi toplamaktadır.
                  </td>
                  <td data-label="Amaç">Pazarlama</td>
                  <td data-label="Süre">2 yıl</td>
                  <td data-label="Tür">Analitik</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Çerez">YESS session</td>
                  <td data-label="Tanım">
                    Kullanıcı oturumu yaratılabilmesi için kullanılmaktadır.
                  </td>
                  <td data-label="Amaç">İşlevsel</td>
                  <td data-label="Süre">2 saat</td>
                  <td data-label="Tür">Oturum</td>
                </tr>
                <tr>
                  <td scope="row" data-label="Çerez">XSRF-TOKEN</td>
                  <td data-label="Tanım">
                    Çerez sitenin kurulumunda <br />baz alınan platform olan Wix
                    website tarafından güvenlik amacıyla sağlanmaktadır.
                  </td>
                  <td data-label="Amaç">Güvenlik</td>
                  <td data-label="Süre">2 saat</td>
                  <td data-label="Tür">Temel</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="flex-col">
          <div class="text-lg font-medium text-gray-900 mb-3 mt-7">
            1.5 Çerez Tercihlerini Nasıl Kontrol Edebilirim?
          </div>
          <div class="text-gray-700">
            Farklı tarayıcılar web siteleri tarafından kullanılan çerezleri
            engellemek ve silmek için farklı yöntemler sunar. Çerezleri
            engellemek / silmek için tarayıcınızın ayarlarını
            değiştirebilirsiniz. Cihazınızda hali hazırda bulunan çerezleri
            silebilir ve çoğu tarayıcıda çerez kaydedilmesini/yerleştirilmesini
            engelleyebilirsiniz. <br />
            Çoğu tarayıcı aşağıdakileri yapmanıza olanak tanır:<br />
            • Kaydedilmiş çerezleri görüntüleme ve dilediklerinizi silme<br />
            • Üçüncü taraf çerezleri engelleme<br />
            • Belli sitelerden çerezleri engelleme<br />
            • Tüm çerezleri engelleme<br />
            • Tarayıcıyı kapattığınızda tüm çerezleri silme<br />
            Çerezleri silmeyi tercih ederseniz ilgili web sitesindeki
            tercihleriniz silinecektir. Ayrıca, çerezleri tamamen engellemeyi
            tercih ederseniz Emüşavirim.net’e ait site dahil olmak üzere birçok
            web sitesi düzgün çalışmayabilir. Tanımlama bilgilerinin nasıl
            yönetileceği ve silineceği hakkında 1.6. maddede yer verdiğimiz
            linkleri kullanarak ayrıntılı bilgi edinebilirsiniz.
          </div>
        </div>
        <div class="flex-col">
          <div class="text-lg font-medium text-gray-900 mb-3 mt-7">
            1.6 Çerezlerin Kullanımı Veri Sahipleri Tarafından Engellenebilir
            Mi?
          </div>
          <div class="text-gray-700">
            Hemen hemen tüm internet tarayıcıları, çerezleri otomatik olarak
            kabul edecek şekilde ön tanımlıdır. Ancak tarayıcınızın ayarlarını
            değiştirerek çerezlere ilişkin tercihlerinizi kişiselleştirebilir,
            çerezleri dilediğiniz gibi kontrol edebilir veya silebilirsiniz.
            (Çerezleri yönetmek tarayıcıdan tarayıcıya farklılık gösterdiğinden
            ayrıntılı bilgi almak için tarayıcının yardım menüsüne
            bakılabilirsiniz.)
            <br />
            <table class="tablelink">
              <thead>
                <tr>
                  <th scope="col">Tarayıcı</th>
                  <th scope="col">Tarayıcı Ayarları</th>
                </tr>
              </thead>
              <tr>
                <td scope="row" data-label="Tarayıcı">Adobe Analytics</td>
                <td data-label="Tarayıcı Ayarları">
                  <a
                    href="http://www.adobe.com/uk/privacy/opt-out.html"
                    target="_blank"
                    class="text-blue-500"
                  >
                    http://www.adobe.com<br />/uk/privacy/<br />opt-out.html
                  </a>
                </td>
              </tr>
              <tr>
                <td scope="row" data-label="Tarayıcı">AOL</td>
                <td data-label="Tarayıcı Ayarları">
                  <a
                    href="https://help.aol.com/articles/restore-security-settings-and-enable-cookie-settings-on-browser"
                    target="_blank"
                    class="text-blue-500"
                  >
                    https://help.aol<br />.com/articles/restore-security-settings-and-enable-cookie-settings-on-browser
                  </a>
                </td>
              </tr>
              <tr>
                <td scope="row" data-label="Tarayıcı">Google Adwords</td>
                <td data-label="Tarayıcı Ayarları">
                  <a
                    href="https://support.google.com/ads/answer/2662922?hl=en"
                    target="_blank"
                    class="text-blue-500"
                  >
                    https://support.google<br />.com/ads/answer/2662922?hl=en
                  </a>
                </td>
              </tr>
              <tr>
                <td scope="row" data-label="Tarayıcı">Google Analytics</td>
                <td data-label="Tarayıcı Ayarları">
                  <a
                    href="https://tools.google.com/dlpage/gaoptout"
                    target="_blank"
                    class="text-blue-500"
                  >
                    https://tools.google.com<br />/dlpage/gaoptout
                  </a>
                </td>
              </tr>
              <tr>
                <td scope="row" data-label="Tarayıcı">Google Chrome</td>
                <td data-label="Tarayıcı Ayarları">
                  <a
                    href="http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647"
                    target="_blank"
                    class="text-blue-500"
                  >
                    http://www.google.com/<br />support/chrome/bin<br />/answer.py?hl=en&answer=95647
                  </a>
                </td>
              </tr>
              <tr>
                <td scope="row" data-label="Tarayıcı">Internet Explorer</td>
                <td data-label="Tarayıcı Ayarları">
                  <a
                    href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                    target="_blank"
                    class="text-blue-500"
                  >
                    https://support.<br />microsoft.com/en-us/help/17442/<br />windows-internet-explorer-delete-manage-cookies
                  </a>
                </td>
              </tr>
              <tr>
                <td scope="row" data-label="Tarayıcı">Opera</td>
                <td data-label="Tarayıcı Ayarları">
                  <a
                    href="http://www.opera.com/browser/tutorials/security/privacy/"
                    target="_blank"
                    class="text-blue-500"
                  >
                    http://www.opera.com<br />/browser/tutorials/security/privacy/
                  </a>
                </td>
              </tr>
              <tr>
                <td scope="row" data-label="Tarayıcı">Safari</td>
                <td data-label="Tarayıcı Ayarları">
                  <a
                    href="https://support.apple.com/kb/ph19214?locale=tr_TR"
                    target="_blank"
                    class="text-blue-500"
                  >
                    https://support.<br />apple.com/kb/ph19214?locale=tr_TR</a
                  >
                </td>
              </tr>
              <tr>
                <td scope="row" data-label="Tarayıcı">Mozilla Firefox</td>
                <td data-label="Tarayıcı Ayarları">
                  <a
                    href="http://support.mozilla.com/en-US/kb/Cookies"
                    target="_blank"
                    class="text-blue-500"
                  >
                    http://support<br />.mozilla.com/en-US/kb/Cookies</a
                  >
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="flex-col">
          <div class="text-lg font-medium text-gray-900 mb-3 mt-7">
            1.7 İlgili Kişi Olarak Haklarınız Nelerdir?
          </div>
          <div class="text-gray-700">
            KVKK’nın 11. maddesi uyarınca kişisel verilerinizin, <br />
            • işlenip işlenmediğini öğrenme, <br />
            • işlenmişse buna ilişkin bilgi talep etme, <br />
            • işlenme amacını ve bunların amacına uygun kullanılıp
            kullanılmadığını öğrenme, <br />
            • yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
            kişileri bilme, <br />
            • kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
            bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin
            kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
            <br />
            • KVKK ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş
            olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması
            hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve
            bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü
            kişilere bildirilmesini isteme, <br />
            • işlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
            edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
            çıkmasına itiraz etme, <br />
            • kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
            uğraması hâlinde zararın giderilmesini talep etme haklarına sahip
            olduğunuzu bildirmek isteriz. Söz konusu haklarınıza ilişkin
            taleplerinizi, <br />
            • “Fulya Mah. Büyükdere Cad. Torun Center Tower Office No: 74D Kat:
            3 D: 10 Şişli, İstanbul” adresindeki merkez ofisimize yazılı başvuru
            formu ile bizzat gelerek, <br />
            • kimliğinizi tespit edebilmek ve yanlış kişilere bilgi vermemek
            adına yazılı olarak noter aracılığı ile veya iadeli taahhütlü mektup
            ile, <br />
            • (varsa) tarafınızca daha önce Emüşavirim.net’e bildirilen ve
            sistemlerimizde kayıtlı bulunan elektronik posta adresini kullanmak
            suretiyle info@ adresine elektronik posta göndererek veya gelecekte
            Kurul’un belirleyeceği diğer yöntemlerle iletebilirsiniz. <br />
          </div>
        </div>
        <div class="flex-col">
          <div class="text-lg font-medium text-gray-900 mb-3 mt-7">
            1.8 Çerez Politikasının Yürürlüğü
          </div>
          <div class="text-gray-700">
            İnternet Sitesi Çerez Politikası 28.10.2020 tarihlidir. Politikanın
            tümünün veya belirli maddelerinin yenilenmesi durumunda yürürlük
            tarihi güncellenecektir. Gizlilik Politikamız https://
            Emüşavirim.net /gizlilik-politikasi yayımlanır ve kişisel veri
            sahiplerinin talebi üzerine ilgili kişilerin erişimine sunulur.
            Kişisel verilerinizin işlenmesine ilişkin detaylı bilgilere
            Emüşavirim.net’e ait https://www. Emüşavirim.net.
            com.tr/kisisel-verilerin-islenmesi KVKK Politikası sayfasından
            ulaşabilirsiniz.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <Footer />
  </div>
</template>
<script>
import Footer from "@/components/website/Footer";
import { PopoverButton, Popover, PopoverPanel } from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";
export default {
  components: {
    Footer,
    MenuIcon,
    Popover,
    PopoverButton,
    PopoverPanel,
    XIcon,
  },
};
</script>
<style scoped lang="scss">
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .app-container {
    max-width: 441px;
  }
}

.pr-20 {
  padding-right: 20px;
}

.bg-gray {
  background: #ededed;
}

.display-none {
  display: none;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.25;
}
</style>
